import { FC, ReactNode, useEffect } from 'react'
import { useShoppingCartOpsDispatch } from '../ShoppingCartProvider'
import { useStoredCart } from './hooks/useStoredCart'

const StoredShoppingCartLoader: FC<{ children?: ReactNode }> = ({ children }) => {
  const storedCart = useStoredCart()
  const dispatch = useShoppingCartOpsDispatch()
  useEffect(() => {
    dispatch({
      type: 'initialize-cart',
      items: storedCart,
    })
  }, [dispatch, storedCart])
  return <>{children}</>
}

export default StoredShoppingCartLoader
