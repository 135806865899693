interface StorageHistory {
  recent: string[]
  initiatedCheckout: boolean
}

export const RECIPIENT_HISTORY_KEY = 'cos_recipient_history'

const emptyStorageHistory: StorageHistory = { recent: [], initiatedCheckout: false }

const clearRecipientHistory = () => {
  try {
    localStorage.removeItem(RECIPIENT_HISTORY_KEY)
  } catch {
    // rip
  }
}

export const storeRecipientHistory = (recent: string[], initiatedCheckout: boolean) => {
  try {
    localStorage.setItem(RECIPIENT_HISTORY_KEY, JSON.stringify({ recent, initiatedCheckout }))
  } catch (error) {
    clearRecipientHistory()
    throw error
  }
}

export const loadRecipientHistory = (): StorageHistory => {
  try {
    const _history = localStorage.getItem(RECIPIENT_HISTORY_KEY)
    if (_history == null) {
      return emptyStorageHistory
    }
    const history = JSON.parse(_history) as StorageHistory
    if (!Array.isArray(history.recent) && typeof history.initiatedCheckout !== 'boolean') {
      throw new Error('Stored recipient history is broken')
    }
    return history
  } catch (error) {
    clearRecipientHistory()
    throw error
  }
}
