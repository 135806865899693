import type { FC } from 'react'
import { useMediaMinMaxLg } from '@g4g/utils/src/react/useMedia'

const LgBreakpoint: FC<{ min?: JSX.Element; max?: JSX.Element }> = ({ min, max }) => {
  const [minLg, maxLg] = useMediaMinMaxLg()
  // Should not use ternary here because the hook defaults to false
  // https://github.com/streamich/react-use/blob/master/src/useMedia.ts
  return (
    <>
      {minLg && min}
      {maxLg && max}
    </>
  )
}

export default LgBreakpoint
