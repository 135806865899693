import { RetryLink } from '@apollo/client/link/retry'

/**
 * default config:
 * delay: { initial: 300, max: Infinity, jitter: true },
 * attempts: { max: 5 }
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-retry#default-configuration
 */
export const retryLink = new RetryLink({
  attempts: {
    retryIf(error) {
      const isCyError = JSON.stringify(error).includes('cypress')
      // Retry if there's an error but is NOT a cypress error
      return !!error && !isCyError
    },
  },
})
