type dict = { [k in string]: string }

const PLURALS: dict = {
  category: 'categories',
}

/**
 * @note can also just export _pluralize in case
 * the dictionary is not needed for some reason
 */
function _pluralize(val: number, word: string): string
function _pluralize(val: dict, word: string): (val: number, word: string) => string
function _pluralize(val: number | dict, word: string, plural = word + 's') {
  const __pluralize = (num: number, word: string, plural = word + 's') =>
    [1, -1].includes(Number(num)) ? word : plural
  if (typeof val === 'object')
    return (num: number, word: string) => __pluralize(num, word, val[word])
  return __pluralize(val, word, plural)
}

/**
 * @returns the singular or plural form of the word based on the input,
 * number, using an optional dictionary if supplied.
 * @example
 * pluralize(0, 'apple'); // 'apples'
 * pluralize(1, 'apple'); // 'apple'
 * pluralize(2, 'apple'); // 'apples'
 * pluralize(2, 'person', 'people'); // 'people'
 *
 * const PLURALS = {
 *  person: 'people',
 *  radius: 'radii'
 * };
 * const autoPluralize = pluralize(PLURALS);
 * autoPluralize(2, 'person'); // 'people'
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/pluralize.md
 */
const pluralize = _pluralize(PLURALS, '')

export default pluralize
