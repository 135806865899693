import { useShopItems } from '@g4g/graphql/src/shop/queries'
import { useRecipient } from '../../recipient/RecipientProvider'

export const useActiveRecipientShopItems = () => {
  const recipient = useRecipient()
  let res = useShopItems({
    cosId: recipient.currentRecipient,
  })
  if (res.data) {
    const  shopItems = res.data.getShopItems?.filter((item) => item.shopItemId !== 'yearlycryptondrops' && item.shopItemId !== '90percentoffboost' && item.shopItemId !== 'goldenboost') ?? null
    res = {
      ...res,
      data: {
        ...res.data,
        getShopItems: shopItems,
      }
    }
  }
  return res
}
