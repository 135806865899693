import { getErrorMessage } from '../react/error'

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
export const gtm_dataLayer_push = (eventName: string, params: object) => {
  try {
    if (typeof window === 'undefined') {
      throw new Error('window undefined when calling gtm dataLayer.push, ' + eventName)
    }

    window?.dataLayer?.push({
      event: eventName,
      /**
       * Event specific params
       */
      ...params,
    })
  } catch (error) {
    window?.dataLayer?.push({
      event: 'exception',
      description: getErrorMessage(error),
    })
  }
}
