import type { FC } from 'react'
import dynamic from 'next/dynamic'
import ShoppingCartButton from './ShoppingCartButton'
import ClientOnly from '@g4g/ui/src/utility/ClientOnly'
const HistoryButton = dynamic(() => import('@/shop/src/components/shared/top-bar/HistoryButton'))

const RightmostItem: FC = () => (
  <div className="flex items-center justify-end h-full space-x-1 lg:space-x-0 lg:w-2/12 xl:w-2/12">
    <div className="flex lg:h-2/5">
      <ClientOnly>
        <HistoryButton className="px-1 py-4 lg:px-3 lg:mr-2 lg:py-2" />
        <ShoppingCartButton />
      </ClientOnly>
    </div>
  </div>
)

export default RightmostItem
