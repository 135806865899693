// !DO NOT EDIT - This file was generated by scripts/share-static

// Note: Trying to destructure process.env variables
// won't work due to the nature of webpack, neither does
// process.env[key] so we generate an extra object so it
// can be accessed by parseFromEnv<T>.
// @see https://nextjs.org/docs/api-reference/next.config.js/environment-variables

export const NEXT_PUBLIC_ENV = {
  NEXT_PUBLIC_ANONYMOUS_USER_JWT: process.env.NEXT_PUBLIC_ANONYMOUS_USER_JWT,
  NEXT_PUBLIC_APPS_DOMAIN: process.env.NEXT_PUBLIC_APPS_DOMAIN,
  NEXT_PUBLIC_APPS_LEGACY_COS_DOMAIN: process.env.NEXT_PUBLIC_APPS_LEGACY_COS_DOMAIN,
  NEXT_PUBLIC_BUCKET_APP_ROOT_PATH: process.env.NEXT_PUBLIC_BUCKET_APP_ROOT_PATH,
  NEXT_PUBLIC_BURN_APP_URL: process.env.NEXT_PUBLIC_BURN_APP_URL,
  NEXT_PUBLIC_COS_COM_APP_URL: process.env.NEXT_PUBLIC_COS_COM_APP_URL,
  NEXT_PUBLIC_E2E_WALLET_CHAIN_ID: process.env.NEXT_PUBLIC_E2E_WALLET_CHAIN_ID,
  NEXT_PUBLIC_E2E_WALLET_PRIVATE_KEY: process.env.NEXT_PUBLIC_E2E_WALLET_PRIVATE_KEY,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  NEXT_PUBLIC_GQL_DEFAULT_ENDPOINT_URI: process.env.NEXT_PUBLIC_GQL_DEFAULT_ENDPOINT_URI,
  NEXT_PUBLIC_GQL_ENDPOINT_URI: process.env.NEXT_PUBLIC_GQL_ENDPOINT_URI,
  NEXT_PUBLIC_GQL_ENDPOINT_URI_PROD: process.env.NEXT_PUBLIC_GQL_ENDPOINT_URI_PROD,
  NEXT_PUBLIC_IMAGE_OPTIMIZER_CF_DISTRIBUTION_ID: process.env.NEXT_PUBLIC_IMAGE_OPTIMIZER_CF_DISTRIBUTION_ID,
  NEXT_PUBLIC_INFURA_PROJECT_ID: process.env.NEXT_PUBLIC_INFURA_PROJECT_ID,
  NEXT_PUBLIC_JSON_RPC_LOCAL_ENDPOINT: process.env.NEXT_PUBLIC_JSON_RPC_LOCAL_ENDPOINT,
  NEXT_PUBLIC_JSON_RPC_MAINNET_ENDPOINT: process.env.NEXT_PUBLIC_JSON_RPC_MAINNET_ENDPOINT,
  NEXT_PUBLIC_JSON_RPC_TESTNET_ENDPOINT: process.env.NEXT_PUBLIC_JSON_RPC_TESTNET_ENDPOINT,
  NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_APPLE: process.env.NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_APPLE,
  NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_FACEBOOK: process.env.NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_FACEBOOK,
  NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_GOOGLE: process.env.NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_GOOGLE,
  NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_TWITCH: process.env.NEXT_PUBLIC_OAUTH_AUTH_SERVER_URI_TWITCH,
  NEXT_PUBLIC_OAUTH_CLIENT_ID_APPLE: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID_APPLE,
  NEXT_PUBLIC_OAUTH_CLIENT_ID_FACEBOOK: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID_FACEBOOK,
  NEXT_PUBLIC_OAUTH_CLIENT_ID_GOOGLE: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID_GOOGLE,
  NEXT_PUBLIC_OAUTH_CLIENT_ID_TWITCH: process.env.NEXT_PUBLIC_OAUTH_CLIENT_ID_TWITCH,
  NEXT_PUBLIC_SHOP_APP_URL: process.env.NEXT_PUBLIC_SHOP_APP_URL,
  NEXT_PUBLIC_UNITY_HTTP_BASE_URL: process.env.NEXT_PUBLIC_UNITY_HTTP_BASE_URL,
  NEXT_PUBLIC_UNITY_HTTP_BASE_URL_RC: process.env.NEXT_PUBLIC_UNITY_HTTP_BASE_URL_RC,
} as const
