import {
  COS_SOCIAL_ACCOUNT_COOKIE_KEY,
  getSocialAccountCookieAttr,
} from '@g4g/utils/src/react/cookie'
import { logErrorMessage } from '@g4g/utils/src/react/error'
import Cookies from 'js-cookie'
import type {
  FetchCosAuthFromPlatformOkResponseData,
  FetchCosAuthFromPlatformReqPlatform,
  SocialAccountStoredData,
} from '../types'
import { generateOauthState } from './seed'
import { isFetchCosAuthFromPlatformOkData } from './unityHttp'

export const COS_OAUTH_STATE_KEY = 'cos_oauth_state'

// Persist the user data across domains
export const storeSocialAccount = (
  userData: FetchCosAuthFromPlatformOkResponseData,
  platform: FetchCosAuthFromPlatformReqPlatform
) => {
  try {
    const storedData: SocialAccountStoredData = { userData, platform }

    return Cookies.set(
      COS_SOCIAL_ACCOUNT_COOKIE_KEY,
      JSON.stringify(storedData),
      getSocialAccountCookieAttr()
    )
  } catch (error) {
    logErrorMessage(error)
    clearSocialAccount()
    return undefined
  }
}

export const clearSocialAccount = () =>
  Cookies.remove(COS_SOCIAL_ACCOUNT_COOKIE_KEY, getSocialAccountCookieAttr())

export const loadSocialAccount = (): SocialAccountStoredData | undefined => {
  try {
    const _user = Cookies.get(COS_SOCIAL_ACCOUNT_COOKIE_KEY)
    if (!_user) {
      return undefined
    }
    const data = JSON.parse(_user) as SocialAccountStoredData
    if (!isFetchCosAuthFromPlatformOkData(data.userData)) {
      throw new Error('Stored userData is broken')
    }
    return data
  } catch (error) {
    clearSocialAccount()
    return undefined
  }
}

export const clearOauthState = () => {
  try {
    localStorage.removeItem(COS_OAUTH_STATE_KEY)
  } catch {}
}

export const storeOauthState = (base64Url: string) => {
  try {
    const state = generateOauthState(base64Url)
    localStorage.setItem(COS_OAUTH_STATE_KEY, state)

    return state
  } catch (_) {
    clearOauthState()
  }
}

export const loadOauthState = (): string => {
  try {
    const state = localStorage.getItem(COS_OAUTH_STATE_KEY)

    if (!state) {
      return ''
    }

    return state
  } catch {
    clearOauthState()
    return ''
  }
}
