import {
  ERC20Names,
  ERC20Symbols,
  NativeCurrencyDetail,
  SupportedNetworkDetail,
  SupportedNetworkIDs,
} from '../types'
import { getDeployedERC20Tokens } from './contract'
import { parseMultipleFromEnv, __DEV__, __STAGING__ } from '@g4g/utils/src/react/environment'

const parseRpcUriAll = () => {
  const [mainnetUrl, testnetUrl, localUrl] = parseMultipleFromEnv({
    NEXT_PUBLIC_JSON_RPC_MAINNET_ENDPOINT: 'https://polygon-rpc.com/',
    NEXT_PUBLIC_JSON_RPC_TESTNET_ENDPOINT: 'https://matic-mumbai.chainstacklabs.com/',
    NEXT_PUBLIC_JSON_RPC_LOCAL_ENDPOINT: 'http://localhost:8545/',
  })

  return { mainnetUrl, testnetUrl, localUrl }
}

const parseWeb3EnvDetails = () => {
  const { mainnetUrl, testnetUrl, localUrl } = parseRpcUriAll()
  const [e2eChainIdStr, infuraId, e2ePrivateKey] = parseMultipleFromEnv({
    NEXT_PUBLIC_E2E_WALLET_CHAIN_ID: SupportedNetworkIDs.Mainnet.toString(),
    NEXT_PUBLIC_INFURA_PROJECT_ID: '1bb9bf1c61754d46a9ed4b4e862415b5',
    NEXT_PUBLIC_E2E_WALLET_PRIVATE_KEY:
      'b149c9dfb40b7127557e9b387dad85b9667a74b1fd58fa900d0d99e788515984',
  })

  const e2eChainId = parseInt(e2eChainIdStr) as SupportedNetworkIDs

  if (!isNetworkSupported(e2eChainId)) {
    throw new Error(`Got Unsupported network ${e2eChainId}`)
  }

  return { mainnetUrl, testnetUrl, localUrl, e2eChainId, infuraId, e2ePrivateKey }
}

export const PolygonNativeCurrencyDetail: NativeCurrencyDetail = {
  name: 'Polygon',
  symbol: 'MATIC',
  decimals: 18,
} as const

export const getSupportedNetworksDetails = (
  chainId: SupportedNetworkIDs
): SupportedNetworkDetail => {
  const { mainnetUrl, testnetUrl, localUrl } = parseRpcUriAll()
  const networkDetails: { [k in SupportedNetworkIDs]: SupportedNetworkDetail } = {
    '137': {
      chainName: 'Polygon Mainnet',
      chainId: SupportedNetworkIDs.Mainnet,
      nativeCurrency: PolygonNativeCurrencyDetail,
      rpcUrls: [mainnetUrl],
      blockExplorerUrls: ['https://polygonscan.com/'],
    },
    '80001': {
      chainName: 'Polygon Testnet',
      chainId: SupportedNetworkIDs.Mumbai,
      nativeCurrency: PolygonNativeCurrencyDetail,
      rpcUrls: [testnetUrl],
      blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
    },
    '1337': {
      chainName: 'Local',
      chainId: SupportedNetworkIDs.Local,
      nativeCurrency: PolygonNativeCurrencyDetail,
      rpcUrls: [localUrl],
    },
  }

  return networkDetails[chainId]
}

export const getAcceptedPaymentsInfo = () => {
  const { Purpose, Dubi } = getDeployedERC20Tokens(SupportedNetworkIDs.Mainnet)

  return {
    tokens: [
      { name: Purpose.name, symbol: Purpose.symbol },
      { name: Dubi.name, symbol: Dubi.symbol },
    ],
  } as {
    tokens: {
      name: ERC20Names
      symbol: ERC20Symbols
    }[]
  }
}

export const internalTokens = getAcceptedPaymentsInfo().tokens

export const isCyWalletAvailable = () => localStorage.getItem('e2e-manual')

export const isNetworkSupported = (id?: number): boolean => {
  if (!id) {
    return false
  }

  if (isCyWalletAvailable() || __DEV__ || __STAGING__) {
    return [
      SupportedNetworkIDs.Mainnet,
      SupportedNetworkIDs.Mumbai,
      SupportedNetworkIDs.Local,
    ].includes(id)
  }

  // Mainnet only on production
  return id === SupportedNetworkIDs.Mainnet
}

export const E2EJsonRpcConnectionInfoFromEnv = () => {
  const { mainnetUrl, testnetUrl, localUrl, e2eChainId, infuraId, e2ePrivateKey } =
    parseWeb3EnvDetails()

  let url: string = ''

  switch (e2eChainId) {
    case SupportedNetworkIDs.Mainnet:
      url = mainnetUrl
      break
    case SupportedNetworkIDs.Mumbai:
      url = testnetUrl
      break
    case SupportedNetworkIDs.Local:
      url = localUrl
      break
    default:
      throw new Error('Unexpected chain Id when parsing E2E from env')
  }

  if (url.includes('infura')) {
    url = `${url}${infuraId}`
  }

  return { url, privateKey: e2ePrivateKey }
}
