import { gtm_shop_item_ecommerce_event } from '.'

const gtm_add_shipping_info = (tier: 'self-login' | 'search-gift') => {
  gtm_shop_item_ecommerce_event('add_shipping_info', undefined, {
    event: {
      // Logged in/Searched gift recipient
      shipping_tier: tier,
    },
  })
}

export default gtm_add_shipping_info
