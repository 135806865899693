import type { FC, ReactNode } from 'react'
import TheHeader from './TheHeader'
import TopBar from '../shared/top-bar/TopBar'

const TheLayout: FC<{ children?: ReactNode }> = ({ children }) => (
  <>
    <TheHeader />
    <TopBar />
    <main className="z-0">{children}</main>
  </>
)

export default TheLayout
