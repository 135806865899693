/**
 * @returns all unique values of an array, based on a provided comparator function.
 * @example
 * // uniqueElementsBy(
 * [
 *  { id: 0, value: 'a' },
 *  { id: 1, value: 'b' },
 *  { id: 2, value: 'c' },
 *  { id: 1, value: 'd' },
 *  { id: 0, value: 'e' }
 * ],
 *  (a, b) => a.id == b.id
 * ); // [ { id: 0, value: 'a' }, { id: 1, value: 'b' }, { id: 2, value: 'c' } ]
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/uniqueElementsBy.md
 */
const uniqueElementsBy = <T>(arr: T[], fn: (a: T, b: T) => boolean) =>
  arr.reduce((acc, v) => {
    if (!acc.some((x) => fn(v, x))) acc.push(v)
    return acc
  }, [] as T[])

export default uniqueElementsBy
