import gtm_remove_from_cart from '@/shop/src/utils/gtm/gtm_remove_from_cart'
import { ProductOrCartItem, toShopItemUniqueId } from '../../util/cart'
import type { ShoppingCartState } from '../types'
import partition from '@g4g/utils/src/partition'

/**
 * Filters out the `itemToRemove` from the array,
 * `purge: true` (bundles) also removes every other item with the same
 * shopItemId.
 */
const removeCartItem = (
  cartItems: ShoppingCartState,
  itemToRemove: ProductOrCartItem,
  purge?: boolean
): ShoppingCartState => {
  const _id = purge ? itemToRemove.shopItemId : toShopItemUniqueId(itemToRemove)
  const [remaining, removed] = partition(
    [...cartItems],
    (item) => (purge ? item.shopItemId : item._id) !== _id
  )

  // Track the removed items
  gtm_remove_from_cart({ items: removed })
  // These are the remaining items in the cart
  return remaining
}

export default removeCartItem
