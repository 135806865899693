import { FC, useEffect } from 'react'
import { useRouter } from 'next/router'
import { GTM_ID } from '@g4g/utils/src/gtm'
import gtm_page_view from '@g4g/utils/src/gtm/gtm_page_view'
import gtm_user_data from '@g4g/utils/src/gtm/gtm_user_data'
import Script from 'next/script'

const handleRouteChange = (url: string) => {
  const dt = document.title
  const title = dt.substring(0, dt.indexOf('|')).trimEnd()

  gtm_page_view(url, title)
}

const GoogleTagManager: FC = () => {
  const { events } = useRouter()

  useEffect(() => {
    // Sending the user data once is fine
    gtm_user_data()
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])

  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      />
    </>
  )
}

export default GoogleTagManager
