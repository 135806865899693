import { FC } from 'react'
import dynamic from 'next/dynamic'
import SharedNavbar from '@g4g/ui/src/internal/header/SharedNavbar'
import ClientOnly from '@g4g/ui/src/utility/ClientOnly'
const Accounts = dynamic(() => import('../shared/account/Accounts'))

const TheHeader: FC = () => {
  return (
    <SharedNavbar
      headerClassNames="lg:fixed"
      currentApp="shop"
      stickyItem={
        <div className="flex items-center sm:space-x-4">
          <ClientOnly>
            <Accounts />
          </ClientOnly>
        </div>
      }
    />
  )
}

export default TheHeader
