import type { FC } from 'react'
import dynamic from 'next/dynamic'
import LgBreakpoint from '@g4g/ui/src/utility/LgBreakpoint'
const RecipientBar = dynamic(() => import('../../recipient/RecipientBar'))

const MiddleItem: FC = () => (
  <LgBreakpoint
    min={
      <div className="flex lg:w-9/12 xl:w-8/12">
        <div className="flex flex-grow h-16 mx-auto bg-white bg-opacity-10 rounded-xl">
          <RecipientBar />
        </div>
      </div>
    }
  />
)

export default MiddleItem
