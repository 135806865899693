import type { ERC20Names } from '@g4g/wallet-modal/types'
import { gtm_shop_item_ecommerce_event } from '.'
import { gtm_purchase_event_params } from './gtm_purchase'
import { GTM_ECommerce_BaseParams } from './types'

interface GTM_ECommerce_RefundParams extends GTM_ECommerce_BaseParams {
  tokenName: ERC20Names
  txHash: string
}

/**
 * Sends a full refund gtm event
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#make_a_purchase_or_issue_a_refund
 */
const gtm_refund = ({ txHash, tokenName }: GTM_ECommerce_RefundParams) => {
  /**
   * *items is required for partial refunds but
   * it can be omitted for full refunds.
   *
   * Send empty items, since the cart is probably cleared and
   * it's intended to call this when a tx fails, which is always
   * a "full refund"
   */
  gtm_shop_item_ecommerce_event('refund', [], {
    event: {
      ...gtm_purchase_event_params(txHash, tokenName),
    },
  })
}

export default gtm_refund
