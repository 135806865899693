import { useEffect } from 'react'
import type { PrpsDubiWallet } from '../wallet/PrpsDubiWallet'
import { Web3Action } from '../components/Web3ContextProvider'

async function isWeb3PrpsDubiWallet(
  wallet: PrpsDubiWallet
): Promise<import('../wallet/Web3PrpsDubiWallet').default | null> {
  const { default: Web3PrpsDubiWallet } = await import('../wallet/Web3PrpsDubiWallet')
  return wallet instanceof Web3PrpsDubiWallet ? wallet : null
}

/**
 * Listens to `chainChanged` and `accountsChanged` events, and resets the provider when either happens.
 * Ethers didn't seem to like anything other than full reset.
 */
export default function useWeb3Events(
  wallet: PrpsDubiWallet | undefined,
  dispatch: React.Dispatch<Web3Action>
) {
  useEffect(() => {
    if (!wallet) {
      return
    }
    const accountsChanged = async (accounts: string[]) => {
      const web3Wallet = await isWeb3PrpsDubiWallet(wallet)
      if (!web3Wallet) {
        return
      }
      // No accounts means disconnected.
      if (!accounts.length) {
        dispatch({ type: 'provider', provider: undefined })
        return
      }
      dispatch({
        type: 'provider',
        provider: await web3Wallet.clone(),
      })
    }
    const chainChanged = async (_oldChain: number, newChain: number) => {
      const web3Wallet = await isWeb3PrpsDubiWallet(wallet)
      if (!web3Wallet) {
        return
      }
      // Metamask doesn't send old chain.
      if (web3Wallet.provider.provider.isMetaMask || newChain) {
        dispatch({
          type: 'provider',
          provider: await web3Wallet.clone(),
        })
      }
    }
    /*
    NOTE: MetaMask on WalletConnect on mobile doesn't send chainChanged events.
    https://github.com/MetaMask/metamask-mobile/issues/2927
     */
    wallet.addListener('chainChanged', chainChanged)
    wallet.addListener('accountsChanged', accountsChanged)
    return () => {
      wallet.removeListener('chainChanged', chainChanged)
      wallet.removeListener('accountsChanged', accountsChanged)
    }
  }, [wallet, dispatch])
}
