/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Currency {
  DUBI = "DUBI",
  GUBI = "GUBI",
  PRPS = "PRPS",
  USD = "USD",
}

export enum ErrorCode {
  RECIPIENT_INELIGIBLE = "RECIPIENT_INELIGIBLE",
}

export enum Faction {
  Kappa = "Kappa",
  KappaPride = "KappaPride",
  LuL = "LuL",
  None = "None",
  PogChamp = "PogChamp",
  SMOrc = "SMOrc",
  TriHard = "TriHard",
}

export enum HeroClass {
  Hunter = "Hunter",
  Mage = "Mage",
  Priest = "Priest",
  Rogue = "Rogue",
  Warrior = "Warrior",
}

export enum LootNotation {
  Amount = "Amount",
  Multiplier = "Multiplier",
  Percentage = "Percentage",
}

export enum NetworkType {
  Binance = "Binance",
  Ethereum = "Ethereum",
  Polygon = "Polygon",
}

export enum Platform {
  Apple = "Apple",
  Discord = "Discord",
  Facebook = "Facebook",
  GooglePlay = "GooglePlay",
  None = "None",
  Reddit = "Reddit",
  Twitch = "Twitch",
  Twitter = "Twitter",
  Youtube = "Youtube",
}

export enum PurchaseCategory {
  Boost = "Boost",
  Bundle = "Bundle",
  ExclusiveDeal = "ExclusiveDeal",
  SpecialDeal = "SpecialDeal",
}

export enum PurchaseType {
  Consumable = "Consumable",
  Subscription = "Subscription",
}

export enum Rarity {
  Epic = "Epic",
  Legendary = "Legendary",
  Rare = "Rare",
  ShinyLegendary = "ShinyLegendary",
}

export enum RewardType {
  AscendanceChip = "AscendanceChip",
  AtheneCoin = "AtheneCoin",
  BljTicket = "BljTicket",
  CasinoChip = "CasinoChip",
  ChallengeChip = "ChallengeChip",
  CommunityChip = "CommunityChip",
  Crypton = "Crypton",
  Custom = "Custom",
  Gem = "Gem",
  GetLuckyChip = "GetLuckyChip",
  Hero = "Hero",
  InsanityChip = "InsanityChip",
  KappaScroll = "KappaScroll",
  LandVoucher = "LandVoucher",
  LegendaryStar = "LegendaryStar",
  MadnessChip = "MadnessChip",
  MegaChip = "MegaChip",
  Pet = "Pet",
  PogChampScroll = "PogChampScroll",
  SalePoints = "SalePoints",
  SeasonalEventItem = "SeasonalEventItem",
  Skin = "Skin",
  TTTTVoucher = "TTTTVoucher",
  TriHardScroll = "TriHardScroll",
  VipExp = "VipExp",
}

export enum TimeInterval {
  Day = "Day",
  Month = "Month",
  Week = "Week",
  Year = "Year",
}

export enum TransactionStatus {
  FAILED = "FAILED",
  PENDING = "PENDING",
  QUEUED = "QUEUED",
  SUCCESS = "SUCCESS",
}

export interface BuildTransactionInput {
  ethAddress: string;
  paymentCurrency: Currency;
  recipientCosId: string;
  purchaserCosId?: string | null;
  products: Product[];
}

export interface DeletePendingTxInput {
  txHash: string;
}

export interface GetShopItemsInput {
  cosId?: string | null;
}

export interface GetUsersInput {
  cosIds?: string[] | null;
}

export interface Product {
  shopItemId: string;
  productId: string;
  quantity: number;
}

export interface UpsertPendingTxInput {
  txHash: string;
  payload: string;
  currency: Currency;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
