import { useMemo } from 'react'
import { roundPrice } from '@/shop/src/utils/shopItemUtils'
import {
  GetShopItems_getShopItems,
  GetShopItems_getShopItems_lootRewardPackage_productPrice,
} from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { useActiveRecipientShopItems } from '../../shared/hooks/useActiveRecipientShopItems'
import { ShoppingCartProduct } from '../provider/types'
import { getProductByUniqueId } from '../util/cart'

export type Price = Omit<GetShopItems_getShopItems_lootRewardPackage_productPrice, '__typename'>

export const zeroPrice: Price = {
  dubiPrice: '0',
  originalPrice: '0',
  price: '0',
} as const

export function useCalculatedPrices(items: readonly ShoppingCartProduct[]) {
  const products = useActiveRecipientShopItems()
  const data = (products.data ?? products.previousData)?.getShopItems
  return useMemo(() => calculatePrices(items, data), [items, data])
}

function calculatePrices(
  items: readonly ShoppingCartProduct[],
  data: GetShopItems_getShopItems[] | null | undefined
): Price & { individual: Price[] } {
  const prices = items.reduce(
    (sums, { productId, shopItemId, totalQuantity }) => {
      const p = getProductByUniqueId(productId, shopItemId, data)
      if (!p) {
        return sums
      }
      const individualPrices = _calculatePrice(totalQuantity, p.lootRewardPackage.productPrice)

      sums.originalPrice += individualPrices.originalPrice
      sums.price += individualPrices.price
      sums.dubiPrice += individualPrices.dubiPrice

      sums.individual.push(
        formatPrices(
          individualPrices.price,
          individualPrices.dubiPrice,
          individualPrices.originalPrice
        )
      )

      return sums
    },
    { originalPrice: 0, dubiPrice: 0, price: 0, individual: [] as Price[] }
  )

  return {
    ...formatPrices(prices.price, prices.dubiPrice, prices.originalPrice),
    individual: prices.individual,
  }
}

function _calculatePrice(
  quantity: number,
  packagePrice: GetShopItems_getShopItems_lootRewardPackage_productPrice
) {
  const countPrice = (price: string | number) => quantity * Number(price) || 0
  return {
    originalPrice: countPrice(packagePrice.originalPrice),
    price: countPrice(packagePrice.price),
    dubiPrice: countPrice(packagePrice.dubiPrice),
  }
}

function formatPrices(prpsPrice: number, dubiPrice: number, originalPrice: number): Price {
  return {
    price: roundPrice(prpsPrice),
    dubiPrice: roundPrice(dubiPrice),
    // Completely hide the 0 Original price
    originalPrice: originalPrice ? roundPrice(originalPrice) : '',
  }
}

export function calculatePrice(
  quantity: number,
  productPrice: GetShopItems_getShopItems_lootRewardPackage_productPrice
): Price {
  const { originalPrice, price, dubiPrice } = _calculatePrice(quantity, productPrice)
  return formatPrices(price, dubiPrice, originalPrice)
}
