/**
 * Concat conditional or a long list of classes together.
 *
 * @example
 *  <a
 *    href={props.href}
 *    className={classNames(
 *      'flex w-full justify-between px-4 py-2 text-left text-sm leading-5',
 *      active ? 'bg-indigo-500 text-white' : 'text-gray-700',
 *      disabled && 'cursor-not-allowed opacity-50'
 *    )}
 *  ></a>
 *
 *  <div
 *    className={classNames(
 *      'my-auto ml-auto mr-0 text-sm text-right',
 *      'font-poppins font-bold text-secondaryLight',
 *      'flex w-full justify-between px-4 py-2 text-left,
 *      'text-sm leading-5'
 *    }
 *  ></div>
 *
 *
 */
export const classNames = (...classes: (false | null | undefined | string)[]): string =>
  classes.filter(Boolean).join(' ')

/**
 * Alternative to :nth-child(4n) selectors or tailwind `gap`
 * to create spacing between flex wrap columns. Returns the
 * first | last | middle classes based on the column position.
 * @example
 * // parent
 * <div className="flex flex-wrap">
 *
 * // child columns
 * <div
 *  className={classNames(
 *    'lg:basis-1/4 lg:justify-center lg:mb-4',
 *    spaceFlexWrap(4, { first: 'lg:pr-2', middle: 'lg:px-2', last: 'lg:pl-2' }, index)
 *  )}
 * >
 */
export const spaceFlexWrap = (
  classes: { first: string; last: string; middle: string },
  colsCount: number,
  colIndex?: number
): string => {
  const colRowIndex = colIndex !== undefined && (colIndex + 1) % colsCount

  if (colRowIndex === 0) {
    return classes.last
  }

  if (colRowIndex === 1) {
    return classes.first
  }

  return colRowIndex ? classes.middle : ''
}
