/**
 *
 * @returns all the unique values in an array
 * @note Array.from instead of ...spread to allow
 * non iterables
 * @example
 * uniqueElements([1, 2, 2, 3, 4, 4, 5]); // [1, 2, 3, 4, 5]
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/uniqueElements.md
 */
const uniqueElements = <T>(arr: T[]) => Array.from(new Set(arr))

export default uniqueElements
