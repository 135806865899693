import { NEXT_PUBLIC_ENV } from '../constants/env'

export const __DEV__ = process.env.NODE_ENV === 'development'
export const __STAGING__ = process.env.NEXT_PUBLIC_STAGING === 'true'

type NextPublicEnvVariables = keyof typeof NEXT_PUBLIC_ENV

/**
 * @returns parsed environment variable,
 * NEXT_PUBLIC_XX_STRINGIFIED assumes a JSON.parsable stringified object,
 * otherwise the plain value is returned
 */
export function parseFromEnv<T>(key: NextPublicEnvVariables, defaultValue: T) {
  try {
    const envValue = NEXT_PUBLIC_ENV[key]

    if (envValue) {
      return (
        key.includes('_STRINGIFIED') ? JSON.parse(envValue) : JSON.parse(`{"v": "${envValue}"}`).v
      ) as T
    }

    return defaultValue
  } catch (err) {
    console.error(`Could not parse ${key} from environment variable`)
    console.error(err)
    return defaultValue
  }
}

/**
 * @returns parsed environment variables
 */
export function parseMultipleFromEnv<T>(variables: { [k in NextPublicEnvVariables]?: T }): T[] {
  return Object.entries(variables).map(([key, defaultValue]) =>
    // Object.entries does not preserve key type
    parseFromEnv(key as NextPublicEnvVariables, defaultValue)
  )
}
