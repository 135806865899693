export const RECENT_SEARCH_RESULTS_KEY = 'cos_recent_search'

const clearStoredSearchResults = () => {
  try {
    localStorage.removeItem(RECENT_SEARCH_RESULTS_KEY)
  } catch {
    // nop
  }
}

export const storeSearchResults = (recent: string[]) => {
  try {
    localStorage.setItem(RECENT_SEARCH_RESULTS_KEY, JSON.stringify(recent))
  } catch {
    clearStoredSearchResults()
  }
}

export const loadStoredSearchResults = (): string[] => {
  try {
    const _results = localStorage.getItem(RECENT_SEARCH_RESULTS_KEY)
    if (_results == null) {
      return []
    }
    const results = JSON.parse(_results)
    if (!Array.isArray(results)) {
      throw new Error('Stored search results are broken')
    }
    return results
  } catch {
    clearStoredSearchResults()
    return []
  }
}
