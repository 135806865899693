import { Currency } from '@g4g/graphql/src/shop/__generated__/globalTypes'
import { ERC20Names } from '@g4g/wallet-modal/types'

export function getCurrency(paymentOption: ERC20Names): Currency {
  switch (paymentOption) {
    case 'Dubi':
      return Currency.DUBI

    case 'Purpose':
      return Currency.PRPS

    default:
      throw new Error('Unsupported ERC20Names currency type: ' + paymentOption)
  }
}
