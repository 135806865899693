import { FC, ReactNode, useEffect } from 'react'
import Router from 'next/router'
import { SocialAccountAction, SocialAccountState } from '../types'
import { clearSocialAccount, loadSocialAccount, storeSocialAccount } from '../util/storage'
import createReducerContext from '@g4g/utils/src/react/createReducerContext'

const reducer = (state: SocialAccountState, action: SocialAccountAction): SocialAccountState => {
  switch (action.type) {
    case 'load-account':
      return {
        ...state,
        loading: false,
        socialAccount: loadSocialAccount(),
      }
    case 'sign-in':
      storeSocialAccount(action.data.userData, action.data.platform)
      return { ...state, loading: false, socialAccount: action.data }
    case 'sign-out':
      clearSocialAccount()
      return { socialAccount: undefined, loading: false, error: undefined }
    case 'oauth-error':
      Router.replace('/login', undefined, { shallow: true })
      return { ...state, error: action.error, loading: false, failCode: action.failCode }
    default:
      throw new Error(`Unexpected social account reducer action: ${JSON.stringify(action)}`)
  }
}

const SocialAccountInit: FC<{ children?: ReactNode }> = ({ children }) => {
  const dispatch = useSocialAccountOpsDispatch()

  useEffect(() => {
    // Load an account if already logged in
    dispatch({ type: 'load-account' })
  }, [])

  return <>{children}</>
}

const [useSocialAccount, useSocialAccountOpsDispatch, ReducerProvider] = createReducerContext(
  reducer,
  {
    socialAccount: undefined,
    error: undefined,
    failCode: undefined,
    loading: true,
  },
  'SocialAccountProvider'
)

const SocialAccountProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <ReducerProvider>
    <SocialAccountInit>{children}</SocialAccountInit>
  </ReducerProvider>
)

export { useSocialAccount, useSocialAccountOpsDispatch }
export default SocialAccountProvider
