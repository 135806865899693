import type { Provider } from '@ethersproject/providers'
import type { Signer } from '@ethersproject/abstract-signer'
import type { Contract, ContractInterface } from '@ethersproject/contracts'
import type {
  CallableContractNames,
  ERC20Detail,
  ERC20Names,
  ExternalERC20Names,
  SupportedNetworkIDs,
} from '../types'
import { getAddress } from './address'

/**
 * Get all the deployed tokens details for the provided chain.
 *
 * @param chainId Supported network id
 * @returns All the ERC20 token details
 */
export const getDeployedERC20Tokens = (
  chainId: SupportedNetworkIDs
): { [k in ERC20Names | ExternalERC20Names]: ERC20Detail } => ({
  Purpose: {
    name: 'Purpose',
    symbol: 'PRPS',
    address: getAddress('Purpose', chainId),
    decimals: 18,
  },
  Dubi: {
    name: 'Dubi',
    symbol: 'DUBI',
    address: getAddress('Dubi', chainId),
    decimals: 18,
  },
  USDC: {
    name: 'USDC',
    symbol: 'USDC',
    address: getAddress('USDC', chainId),
    decimals: 6,
  },
})

/**
 * Get the deployed [Contract Instance](https://docs.ethers.io/v5/api/contract/contract/),
 * which is an abstraction of code that has been deployed to the blockchain.
 *
 * @param {DeployedContractNames} name Deployed Contract Name
 * @param {ethers.ContractInterface} ABI Contract ABI
 * @param {ethers.providers.Provider | ethers.Signer} signerOrProvider Current Signer or Provider
 * @param {SupportedNetworkIDs} networkId Supported Netwrok Id
 * @returns {ethers.Contract} Ethers [Contract Instance](https://docs.ethers.io/v5/api/contract/contract/)
 */
export const getDeployedContractInstance = async (
  name: CallableContractNames,
  ABI: ContractInterface,
  signerOrProvider: Provider | Signer,
  networkId: SupportedNetworkIDs
): Promise<Contract> => {
  try {
    const contractAddress = getAddress(name, networkId)
    const { Contract } = await import('@ethersproject/contracts')

    return new Contract(contractAddress, ABI, signerOrProvider)
  } catch (error: any) {
    console.log(error)
    throw error
  }
}
