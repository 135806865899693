import { FC, useCallback, ReactNode } from 'react'
import { useRouter } from 'next/router'
import { BurnState, BurnAction } from './provider/types'
import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import { useUpdateEffect, useMountedState } from 'react-use'
import { logErrorMessage } from '@g4g/utils/src/react/error'
import type { WalletModalError } from '@g4g/wallet-modal/hooks/wallet/types'
import { useLocalHistoryDelete, useLocalHistoryUpsert } from '@g4g/graphql/src/shop/mutations'
import { getCurrency } from '../../utils/currency'
import gtm_refund from '../../utils/gtm/gtm_refund'

const initialState: BurnState = {
  /**
   * The current submitted `ContractTransaction`,
   * holds the `transaction hash`, `wait()`, etc.
   *
   * note: transaction.chainId is 0 on polygon
   * @see https://docs.ethers.io/v5/api/providers/types/#types--transactions
   */
  transaction: undefined,
  /**
   * The payload.
   */
  payload: undefined,
  /**
   * The current submitted transaction chainId
   */
  transactionChainId: undefined,
  /**
   * Whether the current transaction confirmation
   * was already visited, so we no longer need to
   * display it on the /confirmation page.
   */
  seen: false,
  /**
   * Whether the current transaction is pending
   */
  loading: false,
  /**
   * Error within the current transaction lifecycle
   */
  error: undefined,
}

const reducer = (state: BurnState, action: BurnAction): BurnState => {
  switch (action.type) {
    case 'set-seen':
      return { ...state, seen: action.seen }
    case 'set-loading':
      return { ...state, error: undefined, loading: action.loading }
    case 'set-error':
      return { ...state, error: action.error, loading: false }
    case 'set-submitted-transaction': {
      return {
        ...state,
        transaction: action.submittedTx,
        transactionChainId: action.chainId,
        payload: action.payload,
        currency: action.currency,
        loading: true,
        seen: false,
        error: undefined,
      }
    }
    case 'set-successful-transaction': {
      return {
        ...state,
        transaction: action.submittedTx || state.transaction,
        loading: false,
        error: undefined,
      }
    }
    case 'reset':
      return initialState
    default:
      throw new Error(`Unexpected burn reducer action: ${JSON.stringify(action)}`)
  }
}

const [useBurn, useBurnOpsDispatch, ReducerProvider] = createReducerContext(
  reducer,
  initialState,
  'BurnProvider'
)

const useSafeDispatch = () => {
  const isMounted = useMountedState()
  const dispatch = useBurnOpsDispatch()
  return useCallback(
    (action: BurnAction) => {
      isMounted() && dispatch(action)
    },
    [dispatch, isMounted]
  )
}

const BurnAwaiter: FC<{ children?: ReactNode }> = ({ children }) => {
  const router = useRouter()
  const dispatch = useSafeDispatch()
  const { transaction, seen, payload, currency } = useBurn()
  const [sendToLocalHistory] = useLocalHistoryUpsert()
  const [deleteFromLocalHistory] = useLocalHistoryDelete()

  useUpdateEffect(() => {
    if (transaction && !seen) {
      if (router.pathname === '/checkout') {
        // Navigate to the transaction confirmation page
        router.replace('/checkout/confirmation', undefined, { shallow: true })
      }
      dispatch({ type: 'set-seen', seen: true })
      // Subscribe to the transaction lifecycle globally
      // since this lives inside the <GlobalProviders />
      ;(async () => {
        try {
          await transaction.wait(1)
          // Success
          dispatch({ type: 'set-successful-transaction' })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any -- not gonna start typing this error out.
        } catch (error: any) {
          gtm_refund({ txHash: transaction.hash, tokenName: currency ?? 'Purpose' })
          // Delete the local tx regardless of error type.
          deleteFromLocalHistory({
            txHash: transaction.hash,
          }).catch(() => {
            // Ignore errors.
          })

          // https://docs.ethers.io/v5/api/providers/types/#providers-TransactionResponse
          if (typeof error === 'object' && error.replacement && !error.cancelled) {
            const replacement = error.replacement
            // Replaced transactions only become known once the transaction is already in a block.
            dispatch({ type: 'set-successful-transaction', submittedTx: replacement })
          } else {
            logErrorMessage(error)
            dispatch({ type: 'set-error', error: error as WalletModalError | Error })
          }
        }
      })()
    }
  }, [transaction, seen, dispatch, router, deleteFromLocalHistory, currency])

  const txHash = transaction?.hash

  useUpdateEffect(() => {
    if (txHash && payload && currency) {
      sendToLocalHistory({
        payload: payload,
        txHash: txHash,
        currency: getCurrency(currency),
      })
    }
  }, [txHash, payload, currency, sendToLocalHistory])

  return <>{children}</>
}

const BurnProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ReducerProvider>
      <BurnAwaiter>{children}</BurnAwaiter>
    </ReducerProvider>
  )
}

export { useBurn, useBurnOpsDispatch }
export default BurnProvider
