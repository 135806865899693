import { E2EJsonRpcConnectionInfoFromEnv } from '../util/network'
import type { PrpsDubiWallet } from '../wallet/PrpsDubiWallet'
import type ConnectableWallet from './ConnectableWallet'
import type { ConnectableWalletNames } from '@g4g/utils/src/wallet/detectorStorage'

export class E2EWallet implements ConnectableWallet {
  get logo(): string {
    return '/shared-assets/img/cypress_test_only_logo.png'
  }

  get isAvailable(): boolean {
    return true
  }

  get name(): ConnectableWalletNames {
    return 'E2E'
  }

  async enable(): Promise<PrpsDubiWallet> {
    try {
      const { url, privateKey } = E2EJsonRpcConnectionInfoFromEnv()
      const { JsonRpcProvider } = await import('@ethersproject/providers')
      const provider = new JsonRpcProvider({ url })
      const { Wallet } = await import('@ethersproject/wallet')
      const e2eWallet = new Wallet(privateKey).connect(provider)

      const { default: Web3E2EWallet } = await import('../wallet/Web3E2EWallet')
      return new Web3E2EWallet(e2eWallet)
    } catch (error) {
      console.log(error)
      return {} as PrpsDubiWallet
    }
  }

  async disconnect(): Promise<void> {
    throw new Error('Disconnect is unimplemented for E2EWallet')
  }
}
