import type { ERC20Names } from '@g4g/wallet-modal/types'
import type { GTM_ECommerce_BaseParams } from './types'
import { gtm_dataLayer_push } from '@g4g/utils/src/gtm'
import { gtm_shop_item_ecommerce_event } from '.'
import { roundToTwo } from '../shopItemUtils'

interface GTM_ECommerce_PurchaseParams extends GTM_ECommerce_BaseParams {
  tokenName: ERC20Names
  // Wei
  amount: string
  txHash: string
}

export const gtm_purchase_event_params = (txHash: string, tokenName: ERC20Names) => ({
  transaction_id: txHash,
  // Use this string param for PRPS/DUBI currency since
  // crypto is not supported as a currency by default
  affiliation: tokenName,
})

/**
 * Sends a GA4 recommended purchase event with an additional
 * spend_virtual_currency event to track PRPS/DUBI spent.
 *
 * The purchase event value is the original price in USD.
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#make_a_purchase_or_issue_a_refund
 */
const gtm_purchase = ({ txHash, tokenName, amount }: GTM_ECommerce_PurchaseParams) => {
  const products = gtm_shop_item_ecommerce_event('purchase', undefined, {
    event: {
      ...gtm_purchase_event_params(txHash, tokenName),
    },
  })

  if (products.length > 0) {
    // Send a separate event to track PRPS/DUBI spent
    gtm_dataLayer_push('spend_virtual_currency', {
      // Wei to ETH Number
      virtual_currency_value: roundToTwo(+amount / 10 ** 18),
      virtual_currency_name: tokenName,
      // All cart items instead of a single one,
      // this should track the most frequent items bought together.
      virtual_currency_purchased_items: products.map(({ product }) => product.shopItemId).join(','),
    })
    // Cleanup, for some reason the props get carried over to other events
    // in case of custom events
    window?.dataLayer?.push({ value: null, virtual_currency_name: null, item_name: null })
  }
}

export default gtm_purchase
