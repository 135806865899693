import { useEffect, useReducer } from 'react'
import { MetaMaskWallet } from '../connectors/MetaMaskWallet'
import { WalletConnectWallet } from '../connectors/WalletConnectWallet'
import { E2EWallet } from '../connectors/E2EWallet'
import { PrpsDubiWallet } from '../wallet/PrpsDubiWallet'
import detectEthereumProvider from '@metamask/detect-provider'
import { logErrorMessage } from '@g4g/utils/src/react/error'
import {
  clearDetectedWalletName,
  ConnectableWalletNames,
  storeDetectedWalletName,
} from '@g4g/utils/src/wallet/detectorStorage'

interface Web3LoginDetectorState {
  detectedLogin?: ConnectableWalletNames
  isDetectLoginLoading: boolean
  isMetamaskInstalled: boolean
}

export type Web3LoginDetectorAction =
  | { type: 'detected-login'; detectedLogin: ConnectableWalletNames }
  | { type: 'metamask-installed'; isInstalled: boolean }
  | { type: 'stop-detect' }
  | { type: 'reset' }

const initialState = {
  detectedLogin: undefined,
  isDetectLoginLoading: true,
  isMetamaskInstalled: false,
}

function reducer(
  state: Web3LoginDetectorState,
  action: Web3LoginDetectorAction
): Web3LoginDetectorState {
  switch (action.type) {
    case 'detected-login':
      storeDetectedWalletName(action.detectedLogin)
      return { ...state, detectedLogin: action.detectedLogin, isDetectLoginLoading: false }
    case 'metamask-installed':
      return { ...state, isMetamaskInstalled: action.isInstalled }
    case 'stop-detect':
      clearDetectedWalletName()
      return { ...state, isDetectLoginLoading: false }
    default:
      throw new Error(`Unexpected Web3LoginDetector reducer action: ${JSON.stringify(action)}`)
  }
}

export default function useWeb3LoginDetector(connect?: (wallet: PrpsDubiWallet) => void) {
  const [{ isDetectLoginLoading, isMetamaskInstalled, detectedLogin }, dispatch] = useReducer(
    reducer,
    initialState
  )

  useEffect(() => {
    ;(async () => {
      if (localStorage.getItem('e2e')) {
        connect && connect(await new E2EWallet().enable())
        dispatch({ type: 'detected-login', detectedLogin: 'E2E' })
        return
      }

      if (localStorage.getItem('walletconnect')) {
        connect && connect(await new WalletConnectWallet().enable())
        dispatch({ type: 'detected-login', detectedLogin: 'WalletConnect' })
        return
      }

      const eth = (await detectEthereumProvider({
        silent: true,
      }).catch(() => {})) as any

      let account: string | undefined
      try {
        const accounts = await eth?.request({ method: 'eth_accounts' })

        if (accounts && Array.isArray(accounts) && accounts.length > 0) {
          ;[account] = accounts
        } else {
          // Backwards compatibility with provider mocks
          if (eth && eth.selectedAddress) {
            account = eth.selectedAddress
          }
        }
      } catch (error) {
        logErrorMessage(error)
      }

      if (!eth || eth.addListener === undefined) {
        // Here we can still try Brave, since these conditions
        // apply to the Brave wallet
        if (window.ethereum && account) {
          connect && connect(await new MetaMaskWallet().enable())
          dispatch({ type: 'detected-login', detectedLogin: 'MetaMask' })
        }
        dispatch({ type: 'stop-detect' })
        return
      }

      if (eth) {
        dispatch({ type: 'metamask-installed', isInstalled: true })
      }

      if (account) {
        connect && connect(await new MetaMaskWallet().enable())
        dispatch({ type: 'detected-login', detectedLogin: 'MetaMask' })
        return
      }

      dispatch({ type: 'stop-detect' })
    })()
  }, [dispatch])

  return { detectedLogin, isDetectLoginLoading, isMetamaskInstalled }
}
