import { useCallback } from 'react'
import { FetchResult, gql, MutationResult, useMutation } from '@apollo/client'
import type {
  UpsertPendingTxInputMutation,
  UpsertPendingTxInputMutationVariables,
} from './__generated__/UpsertPendingTxInputMutation'
import type {
  DeletePendingTxMutation,
  DeletePendingTxMutationVariables,
} from './__generated__/DeletePendingTxMutation'
import type { DeletePendingTxInput } from './__generated__/globalTypes'

const UPSERT_PENDING_TX_MUTATION = gql`
  mutation UpsertPendingTxInputMutation($input: UpsertPendingTxInput!) {
    upsertPendingTx(input: $input)
  }
`

// Typings don't seem to work without the explicit return type typing.
export const useLocalHistoryUpsert = (): [
  (
    input: UpsertPendingTxInputMutationVariables['input']
  ) => Promise<FetchResult<UpsertPendingTxInputMutation, Record<string, any>, Record<string, any>>>,
  MutationResult<UpsertPendingTxInputMutation>
] => {
  const [mutate, result] = useMutation<
    UpsertPendingTxInputMutation,
    UpsertPendingTxInputMutationVariables
  >(UPSERT_PENDING_TX_MUTATION)

  if (result.error) {
    console.error('upsertPendingMutation', result.error)
  }

  const doMutate = useCallback(
    (input: UpsertPendingTxInputMutationVariables['input']) =>
      mutate({
        variables: {
          input,
        },
      }),
    []
  )

  return [doMutate, result]
}

const DELETE_PENDING_TX_MUTATION = gql`
  mutation DeletePendingTxMutation($input: DeletePendingTxInput!) {
    deletePendingTx(input: $input)
  }
`

export const useLocalHistoryDelete = (): [
  (
    input: DeletePendingTxInput
  ) => Promise<FetchResult<DeletePendingTxMutation, Record<string, any>, Record<string, any>>>,
  MutationResult<DeletePendingTxMutation>
] => {
  const [mutate, result] = useMutation<DeletePendingTxMutation, DeletePendingTxMutationVariables>(
    DELETE_PENDING_TX_MUTATION
  )

  if (result.error) {
    console.error('useLocalHistoryDelete', result.error)
  }

  const doMutate = useCallback(
    (input: DeletePendingTxInput) =>
      mutate({
        variables: {
          input,
        },
      }),
    []
  )

  return [doMutate, result]
}
