import { verificationRequired } from '@/shop/src/utils/shopItemUtils'
import {
  GetShopItems_getShopItems,
  GetShopItems_getShopItems_limit,
} from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { ShoppingCartItem, ShoppingCartProduct, ShoppingCartState } from '../provider/types'
import { getProductByUniqueId, toShopItemUniqueId } from './cart'

const findShopItem = (cartItem: ShoppingCartItem, shopItems: GetShopItems_getShopItems[]) =>
  shopItems.find((p) => cartProductEqualsShopItem(cartItem, p))

const getOnlyExistingProducts = (cart: ShoppingCartState, shopItems: GetShopItems_getShopItems[]) =>
  cart.filter((cartP) => findShopItem(cartP, shopItems))

const reduceToLimits = (totalQuantity: number, limit: GetShopItems_getShopItems_limit) => {
  if (limit.reachedAllTimeLimit) {
    return 0
  }
  if (limit.noLimit) {
    return totalQuantity
  }
  if (limit.remaining < totalQuantity) {
    return limit.remaining
  }
  return totalQuantity
}

const reduceQuantities = (cart: ShoppingCartState, shopItems: GetShopItems_getShopItems[]) =>
  cart
    .map((cartItem) => {
      const limit = findShopItem(cartItem, shopItems)?.limit
      return {
        ...cartItem,
        totalQuantity: limit
          ? reduceToLimits(cartItem.totalQuantity, limit)
          : cartItem.totalQuantity,
      }
    })
    .filter((item) => item.totalQuantity > 0)

/**
 * Filter out cart products that are not in shopItems,
 * and reset quantities to be within their respective limits.
 */
export const filterCartProducts = (
  cart: ShoppingCartState,
  shopItems: GetShopItems_getShopItems[]
) => {
  // Strict check for `false` because it's usually null/undefined.
  const eligibleShopItems = shopItems.filter((si) => !verificationRequired(si))
  const items = getOnlyExistingProducts(cart, eligibleShopItems)
  const reducedQuantities = reduceQuantities(items, eligibleShopItems)
  return reducedQuantities
}

/** Returns true if cartProduct is the same as the shop item. */
export const cartProductEqualsShopItem = (
  cartProduct: ShoppingCartItem,
  shopItem: GetShopItems_getShopItems
) => toShopItemUniqueId(shopItem) === cartProduct._id

export const findItemQuantity = (product: GetShopItems_getShopItems, items: ShoppingCartItem[]) =>
  getProductByUniqueId(product.lootRewardPackage.productId, product.shopItemId, items)
    ?.totalQuantity

export const toPickedBundleProducts = (
  products: GetShopItems_getShopItems[],
  selectedBundleItems: ShoppingCartItem[]
): ShoppingCartProduct[] =>
  products
    .filter((p) => selectedBundleItems.some((i) => i.productId === p.lootRewardPackage.productId))
    .map((p) => ({
      totalQuantity: findItemQuantity(p, selectedBundleItems) || 0,
      shopItemId: p.shopItemId,
      productId: p.lootRewardPackage.productId,
    }))
