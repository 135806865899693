import type {
  GetShopItems_getShopItems,
  GetShopItems_getShopItems_limit,
  GetShopItems_getShopItems_lootRewardPackage_lootRewards,
} from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { CartProduct } from '../components/shopping-cart/provider/types'
import {
  LootNotation,
  PurchaseCategory,
  PurchaseType,
  Rarity,
  RewardType,
} from '@g4g/graphql/src/shop/__generated__/globalTypes'
import { CART_QUANTITY_MAXIMUM } from '../components/shopping-cart/util/cart'
import { Price } from '../components/shopping-cart/hooks/useCalculatedPrices'
import fromCamelCase from '@g4g/utils/src/fromCamelCase'
import pluralize from '@g4g/utils/src/pluralize'
import uniqueElementsBy from '@g4g/utils/src/uniqueElementsBy'

// Heroes - {level}-{stars}-{faction}-{class} id
// Everything else - {rewardType}-{notation}-{timeInterval}-{customImg}
const uniqueRewardId = (loot: GetShopItems_getShopItems_lootRewardPackage_lootRewards): string => {
  if (loot.rewardType === RewardType.Hero && loot.hero) {
    const { level, stars, faction, class: heroClass } = loot.hero
    return `${level}-${stars}-${faction}-${heroClass}`
  }

  // Skip VipExp & Salepoints (not needed) and to easily retrieve
  // loot.get(RewardType.SalePoints) value from the combined loot
  if (loot.rewardType === RewardType.VipExp || loot.rewardType === RewardType.SalePoints) {
    return loot.rewardType
  }

  return `${loot.rewardType}-${loot.notation}-${loot.timeInterval}-${loot.customImg}`
}

export function calculateRewardTotals(cartedProducts: CartProduct[]) {
  const allRewards = cartedProducts
    .flatMap((p) =>
      p.product.lootRewardPackage.lootRewards?.map((r) => ({
        ...r,
        amount: r.notation === LootNotation.Amount ? r.amount * p.totalQuantity : r.amount,
      }))
    )
    // Need to cast this because `.filter(Boolean)` doesn't type correctly.
    .filter(Boolean) as GetShopItems_getShopItems_lootRewardPackage_lootRewards[]

  // Deduplicate rewards, combining reward amount of each type.
  // Heroes are getting a {level}-{stars}-{faction}-{class} id to
  // group them together.
  const loot = allRewards.reduce((acc, reward) => {
    const id = uniqueRewardId(reward)
    const item = acc.get(id)
    if (item) {
      // Same reward, just add amount.
      acc.set(id, { ...item, amount: reward.amount + item.amount })
    } else {
      acc.set(id, { ...reward })
    }
    return acc
  }, new Map<string, GetShopItems_getShopItems_lootRewardPackage_lootRewards>())

  return {
    loot: Array.from(loot.values()),
    progress: {
      [RewardType.SalePoints]: loot.get(RewardType.SalePoints)?.amount,
      [RewardType.VipExp]: loot.get(RewardType.VipExp)?.amount,
    },
  }
}

/**
 * Returns a string with up to 3 significant decimals.
 */
export function roundPrice(price: number) {
  return (
    price
      // `toString` returns scientific notation (1e-15), no good, use `toFixed`.
      .toFixed(3)
      // Drop one trailing zero.
      .replace(/(.*)0$/, '$1')
  )
}

/**
 * @returns rounded number to at most 2 decimals
 */
export const roundToTwo = (num: number) => Math.round((num + Number.EPSILON) * 100) / 100

/**
 * @returns total cart products originalUsd amount
 */
export const productsTotalUsd = (products: CartProduct[]) =>
  roundToTwo(
    products.reduce(
      (acc, p) => acc + p.totalQuantity * p.product.lootRewardPackage.originalPriceUsd,
      0
    )
  )

const sumPrices = (products: CartProduct[]): Price => {
  const prices = products.reduce(
    (acc, product) => ({
      price: Number(product.product.lootRewardPackage.productPrice.price) + acc.price,
      originalPrice:
        Number(product.product.lootRewardPackage.productPrice.originalPrice) + acc.originalPrice,
      dubiPrice: Number(product.product.lootRewardPackage.productPrice.dubiPrice) + acc.dubiPrice,
    }),
    { price: 0, originalPrice: 0, dubiPrice: 0 }
  )
  return {
    price: roundPrice(prices.price),
    originalPrice: roundPrice(prices.originalPrice),
    dubiPrice: roundPrice(prices.dubiPrice),
  }
}

/**
 * @returns a single CartProduct with all the loot
 * added together, useful when trying to display a single
 * item for a bundle with all the selected loot
 */
export const toSingleCartProduct = (products: CartProduct[]): CartProduct | undefined => {
  // No products to combine the loot for
  if (products.length === 0) {
    return
  }

  const [p] = products

  // One product so nothing else to combine with
  if (products.length === 1) {
    return p
  }

  const { loot } = calculateRewardTotals(products)
  const { price, originalPrice, dubiPrice } = sumPrices(products)

  return {
    ...p,
    product: {
      ...p.product,
      lootRewardPackage: {
        ...p.product.lootRewardPackage,
        productPrice: {
          ...p.product.lootRewardPackage.productPrice,
          price,
          originalPrice,
          dubiPrice,
        },
        lootRewards: loot,
      },
    },
  }
}

export const fillProductPrice = (product: CartProduct, prices?: Price): CartProduct => ({
  ...product,
  product: {
    ...product.product,
    lootRewardPackage: {
      ...product.product.lootRewardPackage,
      productPrice: {
        ...product.product.lootRewardPackage.productPrice,
        ...prices,
      },
    },
  },
})

export const getProductByRarity = (rarity: Rarity, products: GetShopItems_getShopItems[]) =>
  products.find((p) => p.subscription?.rarity === rarity)

export const getProductsByKebabName = (kebab: string, products: GetShopItems_getShopItems[]) =>
  products.filter(({ kebabName }) => kebabName === kebab)

export const allItemsRemainingLimitZero = (products: GetShopItems_getShopItems[]) =>
  products.every(productHasReachedLimit)

export const getProductRemainingQuantity = (limit: GetShopItems_getShopItems_limit | null) => {
  if (limit?.reachedAllTimeLimit) {
    return 0
  }

  if (limit?.noLimit) {
    return CART_QUANTITY_MAXIMUM
  }

  return limit?.remaining ?? 0
}

// UI thing only: Currently used to identify the purchased Bundle Items
export const simplifiedProductId = (shopItem: GetShopItems_getShopItems) => {
  const lootRewardPackage = shopItem.lootRewardPackage
  const productId = lootRewardPackage.productId
  // Only used for bundle items
  if (!isBundle(shopItem)) {
    return productId
  }
  // Leaderboard boost subdeals have their own names, like "Arena IV".
  if (lootRewardPackage.name) {
    return `${lootRewardPackage.name} ${lootRewardPackage.subdivision ?? ''}`.trim()
  }
  // Event names
  if (shopItem.shopItemId === 'bonusbank') {
    const parts = productId.split('.')
    return parts[parts.length - 1].replace('bonusbank', '')
  }
  // Product Number
  const simplifiedMatch = productId.match(/\d+/g)
  return simplifiedMatch ? simplifiedMatch[0] : productId
}

export const exclusiveModelType = (shopItem: GetShopItems_getShopItems): 'Hero' | 'Pet' =>
  shopItem.lootRewardPackage.productId.includes('hero') ? 'Hero' : 'Pet'

export const verificationRequired = (shopItem: GetShopItems_getShopItems) =>
  // Strict check because it might be null and only `false` means "not verified".
  shopItem.availability?.uVerified === false

// This is to group the PurchaseCategory Bundles
// shopItems together by shopItemId
// and for Subscription deals only display the
// `next` available rarity shopItem
export const uniqueShopItems = (items: GetShopItems_getShopItems[]): GetShopItems_getShopItems[] =>
  uniqueElementsBy(
    // First make sure that the subscriptions `next` are handled
    items.filter(({ subscription, purchaseType }) => {
      // There should be always only one `next: true` item in a
      // set of subscription `shopItems`, so just take that one
      if (purchaseType === PurchaseType.Subscription && subscription) {
        return subscription.next
      }
      // Take all the other deals
      return true
    }),
    (a, b) => a.shopItemId === b.shopItemId
  )

/**
 *
 * @returns human readable shop item category,
 * if a shopItem is a Subscription return the purchase type
 * as subscriptions, otherwise turn ExclusiveDeal into
 * exclusive deals, etc.
 */
export const getShopItemCategory = (item: GetShopItems_getShopItems) =>
  pluralize(
    2,
    isSubscription(item)
      ? PurchaseType.Subscription.toLowerCase()
      : fromCamelCase(item.purchaseCategory, ' ')
  )

export const isDealLimitReached = (limit: GetShopItems_getShopItems['limit']) =>
  limit?.reachedAllTimeLimit

export const productHasReachedLimit = (product: GetShopItems_getShopItems) =>
  isDealLimitReached(product.limit)

export const isSubscription = (shopItem: GetShopItems_getShopItems) =>
  shopItem.purchaseType === PurchaseType.Subscription

export const isBundle = (shopItem: GetShopItems_getShopItems) =>
  shopItem.purchaseCategory === PurchaseCategory.Bundle

export const isPeriodicLimitReached = (shopItem: GetShopItems_getShopItems) =>
  shopItem.limit?.remaining === 0 && shopItem.limit?.noLimit === false

export const isLimitReached = (shopItem: GetShopItems_getShopItems) =>
  shopItem.limit?.reachedAllTimeLimit || isPeriodicLimitReached(shopItem)

export const bundleItemName = (bundleItem: GetShopItems_getShopItems, id?: number) =>
  bundleItem.lootRewardPackage.name
    ? simplifiedProductId(bundleItem)
    : `${bundleItem.name}${id !== undefined ? ` ${id}` : ''}`
