import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import { CheckoutState, CheckoutAction } from './provider/types'

const initialState: CheckoutState = {
  loadingBalances: true,
  /**
   * True when price > token balance
   */
  insufficientFunds: { Purpose: false, Dubi: false },
  /**
   * The default payment option.
   */
  paymentOption: 'Purpose',
  /**
   * The calculated amount of locked PRPS needed
   * to be drained for the transaction.
   * (Human readable format)
   */
  lockedPrpsAmountNeeded: undefined,
  error: undefined,
}

const reducer = (state: CheckoutState, action: CheckoutAction): CheckoutState => {
  switch (action.type) {
    case 'update-insufficient-funds':
      if (
        state.insufficientFunds.Purpose === action.prps &&
        state.insufficientFunds.Dubi === action.dubi
      ) {
        if (state.error || state.loadingBalances) {
          return {
            ...state,
            error: undefined,
            loadingBalances: false,
          }
        }
        return state
      }

      return {
        ...state,
        paymentOption: action.paymentOption,
        loadingBalances: false,
        insufficientFunds: {
          Purpose: action.prps !== undefined ? action.prps : state.insufficientFunds.Purpose,
          Dubi: action.dubi !== undefined ? action.dubi : state.insufficientFunds.Dubi,
        },
        error: undefined,
      }
    case 'update-payment-option':
      if (state.paymentOption === action.paymentOption) {
        if (state.error) {
          return {
            ...state,
            error: undefined,
          }
        }
        return state
      }
      return {
        ...state,
        paymentOption: action.paymentOption,
        error: undefined,
      }
    case 'update-locked-prps':
      return {
        ...state,
        lockedPrpsAmountNeeded: action.lockedPrps,
        error: undefined,
      }
    case 'set-error':
      // Errors will retry, so set it as loading.
      return { ...state, error: action.errorMessage, loadingBalances: true }
    case 'reset':
      return initialState
    default:
      throw new Error(`Unexpected checkout reducer action: ${JSON.stringify(action)}`)
  }
}

const [useCheckout, useCheckoutOpsDispatch, CheckoutProvider] = createReducerContext(
  reducer,
  initialState,
  'CheckoutProvider'
)

export { useCheckout, useCheckoutOpsDispatch }
export default CheckoutProvider
