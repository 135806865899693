import { ImageLoader } from 'next/image'

const customImageLoader: ImageLoader = ({ src, width, quality }) => {
  // Add the bucket root path if an app image is requested
  // e.g. the shop bucket has the contents of the out folder
  // inside the shop/ folder.
  // App images are requested via /assets/img, etc.
  const srcPath = src.startsWith('/')
    ? `${process.env.NEXT_PUBLIC_BUCKET_APP_ROOT_PATH}${src}`
    : src

  return `https://${
    process.env.NEXT_PUBLIC_IMAGE_OPTIMIZER_CF_DISTRIBUTION_ID
  }.cloudfront.net/_next/image?w=${width}&q=${quality || 75}&url=${srcPath}`
}

export default customImageLoader
