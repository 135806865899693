import { gtm_dataLayer_push } from '.'

/**
 * Sends a custom js error event.
 *
 * @param fatal true if the error is high priority
 * (conversions, etc.)
 *
 */
const gtm_error = (message: string, fatal?: boolean) => {
  gtm_dataLayer_push('log_error', {
    log_error_message: message,
    log_error_fatal: fatal,
  })
}

export default gtm_error
