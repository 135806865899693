import { ApolloLink } from '@apollo/client'
import { __DEV__, __STAGING__ } from '../../react/environment'
import { errorLink, gqlErrorLink } from './error'
import { httpLink } from './http'
import { retryLink } from './retry'

/**
 * The link order matters here, since they will get executed
 * in sequence down the chain and back up in reverse for the response.
 *
 * Note: putting the errorLink after retryLink will call the errorLink
 * with the retries.
 *
 * @see https://www.apollographql.com/docs/react/api/link/apollo-link-rest#link-order
 */
const links = ApolloLink.from([retryLink, errorLink, gqlErrorLink, httpLink])

export default links
