import type { ShoppingCartItem, UniqueShoppingCartId } from '../provider/types'

/** The max quantity for any item with no limit. */
export const CART_QUANTITY_MAXIMUM = 100000000

/**
 * LootReward package is found on a product returned from
 * the API (gql schema), contains the `productId`.
 */
type LootRewardPackage = {
  productId: string
}

/**
 * Less strict, only expects to have the `shopItemId`,
 * can be useful if bundles are omitted in the current context.
 */
type ShopItem = {
  shopItemId: string
}

type ShopItemOrUndefined = ShopItem | undefined

/**
 * These are expected to be products returned from
 * the API (gql schema), the data structure is more nested so
 * the `productId` is found under a `lootRewardPackage`.
 */
type Product = {
  shopItemId: string
  lootRewardPackage: LootRewardPackage
}

/**
 * These are expected to be strictly the items
 * in the shopping cart, must have at least a
 * `shopItemId` and a `productId`.
 */
type CartItem = {
  shopItemId: string
  productId: string
  totalQuantity: number
}

/**
 * These are expected to be products returned from the API,
 * that are currently also in the shopping cart
 */
type CartProduct = {
  product: Product
  totalQuantity: number
}

/**
 * Either a Product or Shopping cart item.
 */
export type ProductOrCartItem = Product | CartItem
export type CartProductOrCartItem = CartProduct | CartItem

export const isCartItem = (thing: ProductOrCartItem | CartProductOrCartItem): thing is CartItem =>
  (thing as CartItem).productId !== undefined && (thing as CartItem).shopItemId !== undefined

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isProduct = (thing: any): thing is Product =>
  (thing as Product)?.lootRewardPackage?.productId !== undefined &&
  (thing as Product)?.shopItemId !== undefined

/**
 * @returns combined {shopItemId}-{productId}
 */
export const toShopItemUniqueId = (thing: ProductOrCartItem): UniqueShoppingCartId => {
  if (isCartItem(thing)) {
    return `${thing.shopItemId}-${thing.productId}`
  }

  if (isProduct(thing)) {
    return `${thing.shopItemId}-${thing.lootRewardPackage.productId}`
  }

  throw new Error(`Could not construct a unique shopItem _id from ${JSON.stringify(thing)}`)
}

/**
 * @returns true if the item is found inside `shopItems`
 */
export const cartContainsItem = (
  thing: ProductOrCartItem,
  items: CartProductOrCartItem[] | Readonly<CartProductOrCartItem[]>
) =>
  items.some((v) => toShopItemUniqueId(isCartItem(v) ? v : v.product) === toShopItemUniqueId(thing))

/**
 * @returns array of shopItemIds
 */
export const toShopItemIds = (
  shopItems: ShopItemOrUndefined[] | Readonly<ShopItemOrUndefined[]>
): string[] =>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  shopItems.filter(Boolean).map((x) => x!.shopItemId)

/**
 * @returns unique items count -> bundle counts as 1
 */
export const cartItemsCount = (
  items: ShopItemOrUndefined[] | Readonly<ShopItemOrUndefined[]>
): number => new Set(toShopItemIds(items)).size

/**
 * @returns shopping cart item that can be added to the cart
 */
export const toShoppingCartItem = ({ product, totalQuantity }: CartProduct): ShoppingCartItem => ({
  _id: toShopItemUniqueId(product),
  productId: product.lootRewardPackage.productId,
  shopItemId: product.shopItemId,
  totalQuantity: totalQuantity,
})

/**
 * @returns shopping cart items that can be added to the cart
 */
export const toShoppingCartItems = (products: CartProduct | CartProduct[]): ShoppingCartItem[] =>
  Array.isArray(products)
    ? products.map((p) => toShoppingCartItem(p))
    : [toShoppingCartItem(products)]

/**
 * @returns a first found product by shopItemId,
 * used for single deals only (or Bundle metadata,
 * which is shared between multiple products)
 */
export const getProduct = <T extends ShopItem>(
  productShopItemId: string,
  products?: T[] | Readonly<T[]> | null
): T | undefined => products?.find((p) => p.shopItemId === productShopItemId)

export const getProductByUniqueId = <T extends ProductOrCartItem>(
  productId: string,
  shopItemId: string,
  products?: T[] | Readonly<T[]> | null
): T | undefined =>
  products?.find(
    (p) =>
      (isProduct(p) ? p.lootRewardPackage.productId : p.productId) === productId &&
      p.shopItemId === shopItemId
  )

/**
 * @returns all the products with the same shopItemId,
 * used if trying to also retrieve bundles
 */
export const getProducts = <T extends ShopItem>(
  shopItemId: string,
  products?: T[] | Readonly<T[]> | null
): T[] | undefined => products?.filter((p) => p.shopItemId === shopItemId)
