import { FC, ReactNode } from 'react'
import type { RecipientAction, RecipientState } from './provider/types'
import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import { storeRecipientHistory, loadRecipientHistory } from './provider/storage'
import updateRecipient from './provider/util/updateRecipient'
import removeFromRecent from './provider/util/removeFromRecent'
import { loadSocialAccount } from '@g4g/social-account/util/storage'
import gtm_add_shipping_info from '../../utils/gtm/gtm_add_shipping_info'

const reducer = (state: RecipientState, action: RecipientAction): RecipientState => {
  let newState: RecipientState

  switch (action.type) {
    case 'toggle-checkout-in-progress':
      newState = { ...state, initiatedCheckout: action.initiatedCheckout }
      break

    case 'init-recipients':
      newState = {
        recentRecipients: action.recent,
        currentRecipient: action.recipient,
        initiatedCheckout: action.initiatedCheckout,
      }
      break

    case 'select-recipient':
      newState = updateRecipient(state, action.cosId)
      break

    case 'remove-from-recent':
      newState = removeFromRecent(state, action.cosId)
      break

    default:
      throw new Error(`Unexpected recipient reducer action: ${JSON.stringify(action)}`)
  }

  const { recentRecipients, initiatedCheckout } = newState
  storeRecipientHistory(recentRecipients, initiatedCheckout)

  return newState
}

// Immediately invoked client only loadRecipientHistory
// to prevent double calls to getShopItems by not having
// the recipient loaded
let recent: string[] = []
let initiatedCheckout = false
let userId: string | undefined
if (typeof window !== 'undefined') {
  ;({ recent, initiatedCheckout } = loadRecipientHistory())
  userId = loadSocialAccount()?.userData.userId
  // Add the logged in userId to the loaded recent recipients data
  // if not yet added
  if (userId && !recent.includes(userId)) {
    gtm_add_shipping_info('self-login')
    recent.push(userId)
    // Persist the logged in userId in recent
    storeRecipientHistory(recent, initiatedCheckout)
  }
}
const [useRecipient, useRecipientOpsDispatch, ReducerProvider] = createReducerContext(
  reducer,
  {
    recentRecipients: recent,
    currentRecipient: recent[0] || userId,
    initiatedCheckout: initiatedCheckout,
  },
  'RecipientProvider'
)

const RecipientProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <ReducerProvider>{children}</ReducerProvider>
)

export { useRecipient, useRecipientOpsDispatch }
export default RecipientProvider
