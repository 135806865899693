import type { FC } from 'react'
import dynamic from 'next/dynamic'
import cosLogoNavbarImg from '../../../public/shared-assets/img/cos_logo_navbar_lg.webp'
import Image from '../../utility/Image'
import { ButtonView, CurrentApp } from './headerTypes'
import LgBreakpoint from '../../utility/LgBreakpoint'
import { classNames } from '@g4g/utils/src/react/class-names'
const MobileNavbarHamburger = dynamic(() => import('./MobileNavbarHamburger'))
const NavButtons = dynamic(() => import('./NavButtons'))

const SharedNavbar: FC<{
  headerClassNames?: string
  currentApp?: CurrentApp
  stickyItem?: React.ReactNode
}> = ({ headerClassNames, currentApp, stickyItem }) => (
  <header
    className={classNames(headerClassNames, 'top-0 left-0 right-0 z-50 h-header bg-[#212529]')}
  >
    <div className="flex justify-between h-full lg:px-5">
      <div className="flex px-4 space-x-4">
        <a data-cy="header-home-btn" href="/" className="flex items-center h-full">
          <Image
            width={90}
            height={27}
            src={cosLogoNavbarImg}
            // For some reason this completely breaks w/o the loader on `cos-com`.
            // I'm guessing it has to do with the `akamai` loader, but dunno what
            // changed in that regards compared to legacy image component.
            loader={({ src, width, quality }) => `${src}?w=${width}&q=${quality || 75}`}
            alt="Mobile Minigames logo"
            priority
          />
        </a>
        <LgBreakpoint
          min={<NavButtons buttonView={ButtonView.Default} currentApp={currentApp} />}
        />
      </div>
      <div className="flex items-center px-2 space-x-2">
        {stickyItem}
        <LgBreakpoint max={<MobileNavbarHamburger currentApp={currentApp} />} />
      </div>
    </div>
  </header>
)

export default SharedNavbar
