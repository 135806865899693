import { concat, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { parseMultipleFromEnv, __DEV__, __STAGING__ } from '../../react/environment'
import { deployTagLink } from './tag'

const createAuthHttpLink = (uri: string, jwt: string) => {
  let link = createHttpLink({ uri })

  if (jwt) {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        authorization: jwt,
      },
    }))

    return authLink.concat(link)
  }

  return link
}

export const httpLink = (() => {
  const [defaultUri, anonymousJwt] = parseMultipleFromEnv({
    NEXT_PUBLIC_GQL_DEFAULT_ENDPOINT_URI: '',
    NEXT_PUBLIC_ANONYMOUS_USER_JWT: '',
  })
  const link = createAuthHttpLink(defaultUri, anonymousJwt)
  return __DEV__ || __STAGING__ ? concat(deployTagLink, link) : link
})()
