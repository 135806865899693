/**
 * @returns converted string from camelCase. (also works with TitleCase)
 * @example
 * fromCamelCase('someDatabaseFieldName', ' '); // 'some database field name'
 * fromCamelCase('someLabelThatNeedsToBeDecamelized', '-');
 * // 'some-label-that-needs-to-be-decamelized'
 * fromCamelCase('someJavascriptProperty', '_'); // 'some_javascript_property'
 * fromCamelCase('JSONToCSV', '.'); // 'json.to.csv'
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/fromCamelCase.md
 */
const fromCamelCase = (str: string, separator = '_') =>
  str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase()

export default fromCamelCase
