import gtm_ecommerce_event, {
  GTM_ECommerce_TrackedEvents,
} from '@g4g/utils/src/gtm/gtm_ecommerce_event'
import type { CartProduct, ShoppingCartItem } from '../../components/shopping-cart/provider/types'
import client from '@g4g/utils/src/apollo/client'
import { GET_SHOP_ITEMS_QUERY } from '@g4g/graphql/src/shop/queries'
import type {
  GetShopItems,
  GetShopItemsVariables,
} from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import { loadStoredCart } from '../../components/shopping-cart/provider/storage'
import { productsInCart } from '../../components/shopping-cart/hooks/useCartProducts'
import { loadRecipientHistory } from '../../components/recipient/provider/storage'
import { productsTotalUsd, roundToTwo } from '../shopItemUtils'

export const gtm_shop_item_ecommerce_event = (
  eventType: GTM_ECommerce_TrackedEvents,
  /**
   * Get items from storage by default,
   * this is fine for all the event that do not
   * modify the cart (purchase, begin_checkout).
   *
   * Otherwise take the supplied items,
   * i.e. for add_to_cart we only want to take into account
   * the newly added items.
   */
  items: ShoppingCartItem[] = [...loadStoredCart()],
  params?: Partial<{
    event: object
    item: (a: CartProduct, i: number, arr: CartProduct[]) => object
  }>
) => {
  /**
   * Selected recipient userId.
   */
  const [recipientId] = loadRecipientHistory().recent
  /**
   * Read the shopItems cache, needed for getting extra
   * carted items data, i.e. cart item usd price, name.
   */
  const shopItems = client.readQuery<GetShopItems, GetShopItemsVariables>({
    query: GET_SHOP_ITEMS_QUERY,
    variables: { input: { cosId: recipientId } },
  })?.getShopItems

  /**
   * client.readyQuery does not throw & always returns
   * null to indicate that fields are missing, so abandon
   * the event in this case
   *
   * i.e. executing event on manual page load won't work atm
   * because we can't await shopItems
   */
  if (!shopItems) {
    return []
  }

  /**
   * cart items -> cart products
   * cart.length === products.length
   */
  const cartedProducts = productsInCart(items, shopItems, [])

  gtm_ecommerce_event(eventType, cartedProducts, {
    event: {
      /**
       * The monetary value of the event, i.e.
       * purchase -> purchased items USD value
       * add_to_cart -> added items USD value
       */
      value: productsTotalUsd(cartedProducts),
      /**
       * Additional event specific params,
       * i.e. purchaseId
       */
      ...params?.event,
    },
    item: (p, i, arr) => ({
      quantity: p.totalQuantity,
      item_id: p.product.lootRewardPackage.productId,
      item_name: p.product.name,
      item_category: p.product.purchaseCategory,
      item_category2: p.product.purchaseType,
      /**
       * Send the shopItemId as an "additional taxonomy for the item"
       *
       * (it's easier to send it with a predefined param even
       * if it's not the most suitable option)
       */
      item_category3: p.product.shopItemId,
      price: roundToTwo(p.product.lootRewardPackage.originalPriceUsd),
      ...params?.item?.(p, i, arr),
    }),
  })

  return cartedProducts
}
