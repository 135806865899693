import { useEffect, useState } from 'react'

/**
 * Wrapper component for SPA Client side Apollo
 * https://www.apollographql.com/blog/apollo-client/next-js/next-js-getting-started/
 *
 * @param props
 * @returns
 */
export default function ClientOnly({ children }: { children: React.ReactNode }) {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return <>{children}</>
}
