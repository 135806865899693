import { gtm_dataLayer_push } from '.'

const gtm_page_view = (url: string, title: string) => {
  gtm_dataLayer_push('pageview', {
    page: url,
    page_title: title,
  })
}

export default gtm_page_view
