import type { FC } from 'react'
import Image, { ImageLoader, ImageProps } from 'next/image'

/**
 * This is a custom next image loader deployed via this terraform module
 * @see https://github.com/milliHQ/terraform-aws-next-js-image-optimization
 *
 * checkout the cdk/main.tf for more info.
 */
const customImageLoader: ImageLoader = ({ src, width, quality }) => {
  // Add the bucket root path if an app image is requested
  // e.g. the shop bucket has the contents of the out folder
  // inside the shop/ folder.
  // App images are requested via /assets/img, etc.
  const srcPath = src.startsWith('/')
    ? `${process.env.NEXT_PUBLIC_BUCKET_APP_ROOT_PATH}${src}`
    : src

  return `https://${
    process.env.NEXT_PUBLIC_IMAGE_OPTIMIZER_CF_DISTRIBUTION_ID
  }.cloudfront.net/_next/image?w=${width}&q=${quality || 75}&url=${srcPath}`
}

/**
 * A custom next Image wrapper to specify our customLoader prop.
 * TODO next 13: global loaderFile:
 * https://nextjs.org/docs/api-reference/next/image#loader-configuration
 */
const CustomImage: FC<ImageProps> = (props) => <Image {...props} loader={customImageLoader} />

export { customImageLoader }
export default process.env.NEXT_PUBLIC_WITH_CUSTOM_IMAGE_OPTIMIZER ? CustomImage : Image
