import type { FC } from 'react'
import LeftmostItem from './LeftmostItem'
import MiddleItem from './MiddleItem'
import RightmostItem from './RightmostItem'

const TopBar: FC = () => (
  <div className="sticky z-50 lg:mt-header lg:relative lg:h-24 lg:space-x-12 lg:z-1 top-0 flex bg-[#2f323b]">
    <div className="container flex items-center justify-between lg:space-x-12">
      {/* Search deals, back buttons */}
      <div className="flex items-center h-full lg:w-1/12 xl:w-2/12">
        <div className="flex items-center w-full h-full lg:h-2/5">
          <LeftmostItem />
        </div>
      </div>
      {/* Desktop Recipient Select with rewards progress */}
      <MiddleItem />
      <RightmostItem />
    </div>
  </div>
)

export default TopBar
