import type { ShoppingCartState, ShoppingCartItem, ReplaceImpact } from '../types'
import { toShopItemUniqueId } from '../../util/cart'

const cartItemsToPosition = (
  items: ShoppingCartItem[],
  addition: ShoppingCartItem[],
  index: number,
  removedCount: number
) =>
  // If we are slicing from a filtered out array, we have to shift the end
  // slice to index - removedCount
  [...items.slice(0, index), ...addition, ...items.slice(index - removedCount + 1)]

const replaceCartItems = (
  cartItems: ShoppingCartState,
  incomingItems: ShoppingCartItem[],
  replace: ReplaceImpact
): ShoppingCartState => {
  // Should be able to do `incomingItems[0]` for now here because
  // `incomingItems` should always all have the same `shopItemId`
  // which means we don't allow adding different items at the same time
  const itemToReplace = incomingItems[0]
  // Find the item current position inside the cart
  const foundIndex = cartItems.findIndex((i) => i.shopItemId === itemToReplace.shopItemId)

  // Not in the cart yet, so technically we are not replacing
  // just put the incoming to the start
  if (foundIndex === -1) {
    return incomingItems.concat(cartItems)
  }

  // Remove the item/sToReplace from the cart
  const filteredCartItems = cartItems.filter((i) => i.shopItemId !== itemToReplace.shopItemId)

  if (replace === 'hard') {
    // Add the new items to start
    return incomingItems.concat(filteredCartItems)
  }

  // This many items got filtered out of the current cart items
  const removedItemsCount = cartItems.length - filteredCartItems.length

  return cartItemsToPosition(filteredCartItems, incomingItems, foundIndex, removedItemsCount)
}

/**
 * if `replace: soft | hard`
 *
 * Replaces all the cart items with the incoming items but
 * only items with the same `shopItemId`, this is mainly for
 * bundles where we want to replace the existing bundle items
 * with the new ones while keeping the other shopping cart items
 * intact, `hard` places the incoming items to the start, `soft`
 * replaces without any effect on the order of the items.
 *
 * otherwise
 *
 * Either adds to the start of the array, or replaces the existing
 * cart item with the incoming.
 */
const updateCartItem = (
  cartItems: ShoppingCartState,
  incomingItems: ShoppingCartItem[],
  replace?: ReplaceImpact
): ShoppingCartState =>
  replace
    ? replaceCartItems(cartItems, incomingItems, replace)
    : // This reduces into a min of cartItems.length array,
      // used for adding single deals where whe essentially either
      // add or update the quantity
      incomingItems.reduce(
        (updatedItems, currentItem) => {
          // Get the unique id for every incoming item
          // (item that is about to be added to the cart)
          const _id = toShopItemUniqueId(currentItem)
          // Index of the current item in the cart items
          const foundIndex = cartItems.findIndex((i) => _id === i._id)

          // The current item was not found, add
          // concat it to the start of the array
          if (foundIndex === -1) {
            return [currentItem].concat(updatedItems)
          }

          // Update the cart item, so essentially should
          // just update the quantity
          return cartItemsToPosition(updatedItems, [currentItem], foundIndex, 0)
        },
        // Populate the accumulator with the current shopping
        // cart items
        [...cartItems]
      )

export default updateCartItem
