import { isValidHttpUrl } from '../urlUtils'
import { parseFromEnv, __DEV__, __STAGING__ } from './environment'
import Cookies from 'js-cookie'
import { COS_SOCIAL_ACCOUNT_COOKIE_KEY, getSocialAccountCookieAttr } from './cookie'

// These need a deployTag replace
type AvailableDeployTagUrls = 'NEXT_PUBLIC_UNITY_HTTP_BASE_URL' | 'NEXT_PUBLIC_GQL_ENDPOINT_URI'

type DeployTag = `${'production-' | 'staging'}${number}`
// Deploy tags are specified by adding a `?tag=`
// query param
export const URI_TAG_KEY = 'tag'
// All the urls inside .env with this key get replaced
// by the `?tag=` value
export const ENV_REPLACE_KEY = '{deployTag}'
// Tag local storage key
export const STORAGE_TAG_KEY = 'cos_deploy_tag'

/**
 * @returns DeployTag if tag string is a valid deploy tag
 */
export const validateDeployTag = (tag: string): DeployTag | undefined => {
  if (!/^(?:staging|production-)/.test(tag) && tag !== undefined) {
    console.error(`Stored invalid deploy tag ${tag}, fix local storage.`)
  }

  return tag as DeployTag
}

/**
 * @returns parsed deployTag value from /route?tag=staging
 */
export const parseQueryDeployTag = (): DeployTag | undefined => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  const tag = validateDeployTag(params[URI_TAG_KEY])
  return tag
}

export const clearStoredTag = () => {
  try {
    localStorage.removeItem(STORAGE_TAG_KEY)
  } catch {}
}

export const storeTag = (tag: DeployTag) => {
  try {
    localStorage.setItem(STORAGE_TAG_KEY, tag)
  } catch {
    clearStoredTag()
  }
}

export const loadStoredTag = (): DeployTag | undefined => {
  // Always try query params first
  const queryTag = parseQueryDeployTag()

  try {
    const _tag = localStorage.getItem(STORAGE_TAG_KEY)

    if (queryTag) {
      // Clear all the stored data when the tag changes
      if (queryTag !== _tag) {
        localStorage.clear()
        sessionStorage.clear()
        Cookies.remove(COS_SOCIAL_ACCOUNT_COOKIE_KEY, getSocialAccountCookieAttr())
      }
      storeTag(queryTag)
      return queryTag
    }

    if (_tag == null) {
      return undefined
    }

    const deployTag = validateDeployTag(_tag)
    return deployTag
  } catch {
    clearStoredTag()
    return undefined
  }
}

export const addTagToUrl = (_url: string) => {
  const url = new URL(_url)
  const tag = loadStoredTag()
  if (tag) {
    url.searchParams.set('tag', tag)
  }
  return url
}

export const getDeployTagUrlFromEnv = (name: AvailableDeployTagUrls) => {
  const tag = loadStoredTag()

  let urlToReplace = parseFromEnv<string>(name, '')

  // Override for production- that still works from staging build.
  if (tag?.startsWith('production-')) {
    switch (name) {
      case 'NEXT_PUBLIC_GQL_ENDPOINT_URI':
        urlToReplace = parseFromEnv('NEXT_PUBLIC_GQL_ENDPOINT_URI_PROD', '')
        break
      case 'NEXT_PUBLIC_UNITY_HTTP_BASE_URL':
        urlToReplace = parseFromEnv('NEXT_PUBLIC_UNITY_HTTP_BASE_URL_RC', '')
        break
      default:
        break
    }
  }

  // Nothing to replace on prod environment
  if (!__DEV__ && !__STAGING__) {
    return urlToReplace
  }

  if (!urlToReplace || !isValidHttpUrl(urlToReplace)) {
    throw new Error(`${name} Missing endpoint URI`)
  }

  if (!tag && name === 'NEXT_PUBLIC_UNITY_HTTP_BASE_URL') {
    return urlToReplace
  }

  if (!tag) {
    return
  }

  return urlToReplace.replace(ENV_REPLACE_KEY, tag)
}
