/**
 * @returns grouped elements into two arrays,
 * depending on the provided function's truthiness
 * for each element.
 * @example
 * const users = [
 *   { user: 'barney', age: 36, active: false },
 *   { user: 'fred', age: 40, active: true },
 * ];
 * partition(users, o => o.active);
 * // [
 * //   [{ user: 'fred', age: 40, active: true }],
 * //   [{ user: 'barney', age: 36, active: false }]
 * // ]
 * @see https://github.com/30-seconds/30-seconds-of-code/blob/master/snippets/partition.md
 */
const partition = <T>(arr: T[], fn: (a: T, i: number, arr: T[]) => boolean) =>
  arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val)
      return acc
    },
    [[], []] as [T[], T[]]
  )

export default partition
