import '../styles/globals.css'
import type { ReactElement, ReactNode, FC } from 'react'
import type { AppProps } from 'next/app'
import type { NextPage } from 'next'
import DefaultApolloProvider from '@g4g/ui/src/utility/DefaultApolloProvider'
import TheLayout from '../components/layout/TheLayout'
import ShoppingCartProvider from '../components/shopping-cart/ShoppingCartProvider'
import SocialAccountProvider from '@g4g/social-account/components/SocialAccountProvider'
import Web3ContextProvider from '@g4g/wallet-modal/components/Web3ContextProvider'
import RecipientProvider from '../components/recipient/RecipientProvider'
import CheckoutProvider from '../components/checkout/CheckoutProvider'
import BurnProvider from '../components/checkout/BurnProvider'
import ShopSearchProvider from '../components/shared/search/ShopSearchProvider'
import dynamic from 'next/dynamic'
import LgBreakpoint from '@g4g/ui/src/utility/LgBreakpoint'
import GoogleTagManager from '@g4g/ui/src/utility/GoogleTagManager'
import { __DEV__ } from '@g4g/utils/src/react/environment'
const DeployTag = dynamic(() => import('@g4g/ui/src/utility/DeployTag'))
const Breadcrumbs = dynamic(() => import('../components/shared/Breadcrumbs'))

const PageTransitionLoadingBar = dynamic(() => import('next-progress'))

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// These providers are global across all layouts
const GlobalProviders: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <DefaultApolloProvider>
      <Web3ContextProvider>
        <SocialAccountProvider>
          <RecipientProvider>
            <ShoppingCartProvider>
              <ShopSearchProvider>
                <BurnProvider>{children}</BurnProvider>
              </ShopSearchProvider>
            </ShoppingCartProvider>
          </RecipientProvider>
        </SocialAccountProvider>
      </Web3ContextProvider>
    </DefaultApolloProvider>
  )
}

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  /**
   * Use the layout defined at the page level, if available
   *
   * otherwise use the
   *
   * standard layout -> this one has the TopBar with the
   * Shopping Cart & Recipient Bar
   */
  const getLayout =
    Component.getLayout ??
    ((page: ReactElement) => (
      // These providers do not need to be exposed
      // globally & are only available on the standard layout
      <CheckoutProvider>
        {/* Breadcrumbs must have static height to avoid content layout shift, because they're client-side only. */}
        <div className="container items-center hidden h-6 mb-3 mt-9 lg:flex">
          <LgBreakpoint min={<Breadcrumbs />} />
        </div>
        {page}
      </CheckoutProvider>
    ))

  return (
    <>
      {!__DEV__ && <GoogleTagManager />}
      <PageTransitionLoadingBar options={{ showSpinner: false }} delay={300} />
      <GlobalProviders>
        <DeployTag />
        <TheLayout>{getLayout(<Component {...pageProps} />)}</TheLayout>
      </GlobalProviders>
    </>
  )
}

export default MyApp
