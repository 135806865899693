/**
 * https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
 * TODO: could use that to retry queries when they fail.
 */
import { gql, useQuery } from '@apollo/client'
import type {
  GetGiftRecipients,
  GetGiftRecipientsVariables,
} from './__generated__/GetGiftRecipients'
import type { GetShopItems, GetShopItemsVariables } from './__generated__/GetShopItems'
import type { BuildTransaction, BuildTransactionVariables } from './__generated__/BuildTransaction'
import type { GetShopHistory, GetShopHistoryVariables } from './__generated__/GetShopHistory'
import type { GetUsersVariables, GetUsers } from './__generated__/GetUsers'

export const USER_FRAGMENT = gql`
  fragment CoreUserFields on CoSUser {
    cosId
    faceUrl
    primaryLogin {
      displayName
      platform
      followers
    }
    userState {
      vipXP
      salePoints
    }
  }
`

export const GET_GIFT_RECIPIENTS_QUERY = gql`
  query GetGiftRecipients(
    $name: String
    $cosId: String
    $metamaskEthAddress: String
    $after: String
    $first: Int
    $last: Int
    $before: String
  ) {
    getGiftRecipients(
      name: $name
      cosId: $cosId
      metaMaskEthAddress: $metamaskEthAddress
      after: $after
      first: $first
      last: $last
      before: $before
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...CoreUserFields
        }
      }
    }
  }
  ${USER_FRAGMENT}
`

export const useCoSGiftRecipients = (variables: GetGiftRecipientsVariables) => {
  const name = variables.name
  const result = useQuery<GetGiftRecipients, GetGiftRecipientsVariables>(
    GET_GIFT_RECIPIENTS_QUERY,
    {
      variables,
      // Empty search input or a less than 2 characters
      // search, which is a user service limit
      skip: !name || name.length < 2,
    }
  )

  return result
}

export const GET_USERS_QUERY = gql`
  query GetUsers($input: GetUsersInput!) {
    getUsers(input: $input) {
      ...CoreUserFields
    }
  }
  ${USER_FRAGMENT}
`

export const useCoSUsers = (variables: GetUsersVariables) => {
  const input = variables.input
  const result = useQuery<GetUsers, GetUsersVariables>(GET_USERS_QUERY, {
    variables,
    // No users to query
    skip: !input.cosIds || input.cosIds.length === 0,
  })

  return result
}

export const SHOP_ITEM_FRAGMENT = gql`
  fragment CoreShopItemFields on ShopItem {
    shopItemId
    kebabName
    purchaseCategory
    purchaseType
    subscription {
      rarity
      next
    }
    limit {
      current
      remaining
      max
      hasAllTimeLimit
      reachedAllTimeLimit
      noLimit
    }
    name
    description
    disclaimer
    assets {
      bgFrontPage
      bgCard
      front
    }
    discount
    availability {
      uVerified
    }
    lootRewardPackage {
      name
      productId
      subdivision
      productPrice {
        price
        originalPrice
        dubiPrice
      }
      bundlePrice {
        price
        originalPrice
        dubiPrice
      }
      lootRewards {
        amount
        rewardType
        notation
        timeInterval
        timeSpan
        customImg
        clarification
        hero {
          class
          faction
          level
          stars
        }
      }
      rarity
      originalPriceUsd
    }
    faction
  }
`

export const GET_SHOP_ITEMS_QUERY = gql`
  query GetShopItems($input: GetShopItemsInput!) {
    getShopItems(input: $input) {
      ...CoreShopItemFields
    }
  }
  ${SHOP_ITEM_FRAGMENT}
`

export const useShopItems = (input: GetShopItemsVariables['input'] = {}) => {
  const result = useQuery<GetShopItems, GetShopItemsVariables>(GET_SHOP_ITEMS_QUERY, {
    variables: {
      input,
    },
  })

  if (result.error) {
    console.error('Error in useShopItems', result.error)
  }

  return result
}

const GET_SHOP_HISTORY_QUERY = gql`
  query GetShopHistory(
    $pCosOrTwitchId: String
    $cosId: String
    $transactionHash: String
    $after: String
    $first: Int
    $last: Int
    $before: String
  ) {
    getShopHistory(
      pCosOrTwitchId: $pCosOrTwitchId
      cosId: $cosId
      transactionHash: $transactionHash
      after: $after
      first: $first
      last: $last
      before: $before
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          purchaserCosId
          purchaserPCosId
          recipientCosId
          recipientPCosId
          transactionStatus
          transactionHash
          paymentCurrency
          paymentNetwork
          paymentAmount
          purchaseItems {
            totalQuantity
            product {
              ...CoreShopItemFields
            }
            gameStatus
            gameTimestamp
            queuedAmount
            recipientLimit
            productLimit
          }
          datePurchased
          mintedDUBI
        }
      }
    }
  }
  ${SHOP_ITEM_FRAGMENT}
`

export function useShopHistory(variables?: GetShopHistoryVariables) {
  const cosId = variables?.cosId
  const transactionHash = variables?.transactionHash
  const result = useQuery<GetShopHistory, GetShopHistoryVariables>(GET_SHOP_HISTORY_QUERY, {
    variables,
    // We can't search for history if there's something
    // wrong with both filter params
    skip: !cosId && !transactionHash,
    // Known issue: keeps polling after component unmount
    // (DEV environment only at the moment, so can be ignored)
    // https://github.com/apollographql/apollo-client/issues/9431
    // https://github.com/apollographql/apollo-client/issues/9819
    fetchPolicy: 'cache-and-network',
  })

  return result
}

const BUILD_TRANSACTION_QUERY = gql`
  query BuildTransaction($input: BuildTransactionInput!) {
    buildTransaction(input: $input) {
      payload
      hodlKeys
      amount
      error
    }
  }
`
export function useTxPayload(variables: BuildTransactionVariables) {
  const input = variables.input
  return useQuery<BuildTransaction, BuildTransactionVariables>(BUILD_TRANSACTION_QUERY, {
    variables,
    // If no products, don't bother sending.
    // If no recipient, don't bother sending.
    skip: !input.products.length || !input.recipientCosId,
    // Cache-and-network, because purchase state may have changed, but also unlikely.
    fetchPolicy: 'cache-and-network',
  })
}
