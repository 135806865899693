import { relayStylePagination } from '@apollo/client/utilities'
import { InMemoryCache } from '@apollo/client'

/**
 * Relay style pagination
 *
 * @see https://relay.dev/graphql/connections.htm
 * @see https://www.apollographql.com/docs/react/pagination/cursor-based#relay-style-cursor-pagination
 */
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getGiftRecipients: relayStylePagination(),
        getShopHistory: relayStylePagination(),
      },
    },
  },
})

export default cache
