import Cookies from 'js-cookie'
import { parseFromEnv } from './environment'

export const COS_SOCIAL_ACCOUNT_COOKIE_KEY = 'cos_social_account'

/**
 * @returns Attributes needed to create/delete the social account
 * cookie.
 */
export const getSocialAccountCookieAttr = (): Cookies.CookieAttributes => {
  const domain = parseFromEnv('NEXT_PUBLIC_APPS_DOMAIN', 'mobileminigames.com')
  return {
    domain: `${domain.includes('localhost') ? '' : '.'}` + domain,
    expires: 365 * 10,
    sameSite: 'Lax',
    secure: true,
  }
}
