import type { RecipientState } from '../types'

export default function updateRecipient(state: RecipientState, cosId?: string): RecipientState {
  // Deselect the current recipient
  if (!cosId) {
    return { ...state, currentRecipient: undefined }
  }

  // No updates needed
  if (cosId === state.currentRecipient) {
    return state
  }

  const pickedPreviously = state.recentRecipients.includes(cosId)

  // Recipient was already picked at some point
  if (pickedPreviously) {
    // Filter the recipient out of recent recipients
    // & move it to the top
    const filtered = state.recentRecipients.filter((r) => r !== cosId)
    return { ...state, currentRecipient: cosId, recentRecipients: [cosId, ...filtered] }
  }

  return { ...state, currentRecipient: cosId, recentRecipients: [cosId, ...state.recentRecipients] }
}
