import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import useWeb3Events from '../hooks/useWeb3Events'
import type { PrpsDubiWallet } from '../wallet/PrpsDubiWallet'
import useWeb3LoginDetector from '../hooks/useWeb3LoginDetector'

export interface Web3ContextProps {
  /** Eth address, if connected. */
  account?: string
  /** Chain id. Ex. `1` for eth mainnet. If connected. */
  chainId?: number
  /** The provider, if connected. */
  provider?: PrpsDubiWallet
  /**
   * Connect a wallet to the web3 context.
   */
  connect: (wallet: PrpsDubiWallet | undefined) => void
  /** Disconnect wallet. */
  disconnect: () => void
  /**
   * true till web3LoginDetector didn't found any web3 login
   * or till the account is obtained from the provider
   */
  loadingAccount: boolean
  error?: Error
}

const Web3Context = createContext<Web3ContextProps>({
  connect: () => {},
  disconnect: () => {},
  loadingAccount: true,
})
export const useWeb3Provider = (): Web3ContextProps => {
  return useContext<Web3ContextProps>(Web3Context)
}

interface Web3State {
  loadingAccount: boolean
  provider?: PrpsDubiWallet
  account?: string
  chainId?: number
  error?: Error
}

const initialState: Web3State = {
  loadingAccount: true,
  error: undefined,
  provider: undefined,
  account: undefined,
  chainId: undefined,
}

export type Web3Action =
  | { type: 'account-info'; account: string; chainId: number }
  | { type: 'account-error'; error: Error }
  | { type: 'reset' }
  | { type: 'provider'; provider?: PrpsDubiWallet }

function reducer(state: Web3State, action: Web3Action) {
  switch (action.type) {
    case 'provider':
      return { ...state, provider: action.provider }
    case 'account-info':
      return { ...state, account: action.account, chainId: action.chainId, loadingAccount: false }
    case 'account-error':
      return { ...state, error: action.error, loadingAccount: false }
    case 'reset':
      return { ...initialState, loadingAccount: false }
    default:
      throw new Error()
  }
}

const Web3ContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [{ chainId, provider, account, loadingAccount, error }, dispatch] = useReducer(
    reducer,
    initialState
  )

  useEffect(() => {
    if (!provider) {
      if (!loadingAccount) {
        dispatch({ type: 'reset' })
      }
      return
    }
    let stale = false
    ;(async () => {
      try {
        const [address, chainId] = await Promise.all([provider.getAddress(), provider.getChainId()])
        if (!stale) {
          dispatch({ type: 'account-info', account: address, chainId })
        }
      } catch (error) {
        if (!stale) {
          dispatch({
            type: 'account-error',
            error: new Error('broken provider.getAddress or provider.getChainId call'),
          })
        }
        console.error(error)
      }
    })()
    return () => {
      stale = true
    }
  }, [dispatch, provider])

  useWeb3Events(provider, dispatch)

  const connect = useCallback((wallet?: PrpsDubiWallet) => {
    dispatch({ type: 'provider', provider: wallet })
  }, [])

  const { isDetectLoginLoading, detectedLogin } = useWeb3LoginDetector(connect)

  useEffect(() => {
    if (!detectedLogin && !isDetectLoginLoading) {
      dispatch({ type: 'reset' })
    }
  }, [isDetectLoginLoading, detectedLogin])

  const value = useMemo(
    () => ({
      account,
      chainId,
      provider,
      connect,
      loadingAccount,
      error,
      disconnect: () => {
        provider?.disconnect()
        dispatch({ type: 'reset' })
      },
    }),
    [account, chainId, provider, connect, loadingAccount, error]
  )

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>
}

export default Web3ContextProvider
