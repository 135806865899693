import gtm_error from '../gtm/gtm_error'

/**
 * Get unknown error messages inside catch blocks.
 *
 * @see https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
 */
type ErrorWithMessage = {
  message: string
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  )
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError

  try {
    return new Error(JSON.stringify(maybeError))
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError))
  }
}

export function logErrorMessage(error: unknown, fatal?: boolean, ...others: unknown[]) {
  if (!error) {
    return
  }

  const message = toErrorWithMessage(error).message
  console.error(message, others)
  gtm_error(message, fatal)
}

export function getErrorMessage(error: unknown, silent?: boolean) {
  const message = toErrorWithMessage(error).message

  if (!silent) {
    console.error(message)
  }

  return message
}
