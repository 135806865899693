import {
  FetchCosAuthFromPlatformFailCodes,
  FetchCosAuthFromPlatformResponseFailOrOk,
  FetchCosAuthFromPlatformOkResponseData,
  FetchCosAuthFromPlatformRequestData,
  UnityHttpRequests,
  UnityHttpResponses,
  FetchCosAuthFromPlatformOk,
  FetchCosAuthFromPlatformFail,
  FetchCosAuthFromPlatformResponseFailCodeOrOkData,
  SocialAccountMajorProvider,
  FetchCosAuthFromPlatformReqPlatform,
} from '../types'
import { logErrorMessage } from '@g4g/utils/src/react/error'
import { getDeployTagUrlFromEnv } from '@g4g/utils/src/react/deploy-tag'

const fetchCosAuthFromPlatformRequestBody = (
  reqData: FetchCosAuthFromPlatformRequestData
): string => {
  const { authCode, platform, appleDisplayName, clientType } = reqData

  if (authCode === '') {
    throw new Error(
      `${UnityHttpRequests.FetchCosAuthFromPlatformRequest} Missing authCode when constructing request body`
    )
  }

  return JSON.stringify({
    version: 1,
    data: {
      [UnityHttpRequests.FetchCosAuthFromPlatformRequest]: {
        authCode,
        platform,
        appleDisplayName,
        clientType,
      },
    },
  })
}

const isFetchCosAuthFromPlatformOkResponse = (
  thing: FetchCosAuthFromPlatformResponseFailOrOk
): thing is FetchCosAuthFromPlatformOk => {
  const res = (thing as FetchCosAuthFromPlatformOk).FetchCosAuthFromPlatformOkResponse
  if (!res) {
    return false
  }
  return res.userId !== undefined
}

const isFetchCosAuthFromPlatformFailResponse = (
  thing: FetchCosAuthFromPlatformResponseFailOrOk
): thing is FetchCosAuthFromPlatformFail => {
  const res = (thing as FetchCosAuthFromPlatformFail).FetchCosAuthFromPlatformFailResponse
  if (!res) {
    return false
  }
  const [message, errorCode] = res
  // We should have a message inside the fail Response and
  // a supported error code for this fail response to be valid
  return message !== '' && Object.values(FetchCosAuthFromPlatformFailCodes).includes(errorCode)
}

export const fetchCosAuthFromPlatformParseBodyPlatform = (
  query: any,
  provider: SocialAccountMajorProvider
): FetchCosAuthFromPlatformReqPlatform => {
  // For google we need to check if we sent a Youtube scope
  // inside the oauth request, so that we can pass the correct
  // platform to unity
  if (provider !== 'Google') {
    return provider
  }

  if (!query || !Object.prototype.hasOwnProperty.call(query, 'scope')) {
    throw new Error(
      `Unable to determine Google platform alias (Youtube/Google play), query scope not found ${JSON.stringify(
        query
      )}`
    )
  }

  const isYoutube = query.scope.includes('youtube')

  return isYoutube ? 'Youtube' : 'GooglePlay'
}

export const isFetchCosAuthFromPlatformOkData = (
  thing: FetchCosAuthFromPlatformResponseFailCodeOrOkData
): thing is FetchCosAuthFromPlatformOkResponseData => {
  const { displayName, userId } = thing as FetchCosAuthFromPlatformOkResponseData
  // All the mandatory props should be OK for this response
  // data to be valid
  return displayName !== undefined && userId !== undefined
}

export const isFetchCosAuthFromPlatformFailCode = (
  thing: FetchCosAuthFromPlatformResponseFailCodeOrOkData
): thing is FetchCosAuthFromPlatformFailCodes =>
  Object.values(FetchCosAuthFromPlatformFailCodes).includes(
    thing as FetchCosAuthFromPlatformFailCodes
  )

/**
 * Exchange oauth authCode for cos auth user data.
 *
 * @see https://reese015.gitlab.io/cos-documentation/server/features/sociallogin/cos-platform-auth/
 */
export const fetchCosAuthFromPlatform = async (
  reqData: FetchCosAuthFromPlatformRequestData
): Promise<FetchCosAuthFromPlatformOkResponseData | FetchCosAuthFromPlatformFailCodes> => {
  const res = await unityHttpRequest(
    UnityHttpRequests.FetchCosAuthFromPlatformRequest,
    fetchCosAuthFromPlatformRequestBody(reqData)
  )

  // Success, only returns the data needed
  // from the response and discards other
  if (isFetchCosAuthFromPlatformOkResponse(res)) {
    const { userId, displayName } = res.FetchCosAuthFromPlatformOkResponse
    const scrapedOkResponseData: FetchCosAuthFromPlatformOkResponseData = {
      userId,
      displayName,
    }
    return scrapedOkResponseData
  }

  // Domain specific fail, returns the supported fail code
  if (isFetchCosAuthFromPlatformFailResponse(res)) {
    const [_, failErrorCodeResponse] = res.FetchCosAuthFromPlatformFailResponse
    return failErrorCodeResponse
  }

  // Did not recognize this response
  throw new Error(`Received an unexpected 200 response: ${JSON.stringify(res)}`)
}

const unityHttpRequest = async <T extends UnityHttpRequests>(
  name: T,
  body: string
): Promise<UnityHttpResponses[T]> => {
  try {
    const unityHttpBaseUrl = getDeployTagUrlFromEnv('NEXT_PUBLIC_UNITY_HTTP_BASE_URL')
    const rawResponse = await fetch(`${unityHttpBaseUrl}/${name}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body,
    })

    if (!rawResponse.ok) {
      throw new Error(`${name} NOT OK: ${JSON.stringify(rawResponse)}`)
    }

    const content = await rawResponse.json()
    return content.data
  } catch (error) {
    logErrorMessage(error)
    // Throw this back to be handled as needed
    throw error
  }
}
