import { ShoppingCartState } from './types'

export const CART_KEY = 'cos_shop_cart'

const clearStoredCart = () => {
  try {
    localStorage.removeItem(CART_KEY)
  } catch {
    // nop
  }
}

export const storeCart = (cart: ShoppingCartState) => {
  try {
    localStorage.setItem(CART_KEY, JSON.stringify(cart))
  } catch {
    clearStoredCart()
  }
}

export const loadStoredCart = (): ShoppingCartState => {
  try {
    const _cart = localStorage.getItem(CART_KEY)
    if (_cart == null) {
      return []
    }
    const cart = JSON.parse(_cart)
    if (!Array.isArray(cart)) {
      throw new Error('Stored shopping cart is broken')
    }
    return cart
  } catch {
    clearStoredCart()
    return []
  }
}
