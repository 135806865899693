import { ApolloLink } from '@apollo/client'
import { getDeployTagUrlFromEnv } from '../../react/deploy-tag'

export const deployTagLink = new ApolloLink((operation, forward) => {
  const uri = getDeployTagUrlFromEnv('NEXT_PUBLIC_GQL_ENDPOINT_URI')

  if (uri) {
    // Quick Prod
    // operation.setContext({ uri: 'https://production-production.g4g-shop-api.com/graphql' })
    operation.setContext({ uri })
  }

  return forward(operation)
})
