import addresses from '../addresses.json'
import type {
  CallableContractNames,
  ExternalERC20Names,
  NonHumanEOANames,
  SupportedNetworkIDs,
} from '../types'

/**
 * Get the Address of the the deployed Contract.
 *
 * @param {DeployedContractNames} name Deployed Contract Name
 * @param {SupportedNetworkIDs} networkId Supported Netwrok Id
 * @returns {string} Contract Address
 */
export const getAddress = (
  name: CallableContractNames | NonHumanEOANames | ExternalERC20Names,
  networkId: SupportedNetworkIDs
): string => {
  const address = addresses[networkId][name]

  if (!address) {
    throw new Error('Trying to access non existing contract address')
  }

  return address
}
