import LgBreakpoint from '@g4g/ui/src/utility/LgBreakpoint'
import dynamic from 'next/dynamic'
import type { FC } from 'react'
import { useRouter } from 'next/router'
import Breadcrumbs from '../Breadcrumbs'
const ShopSearch = dynamic(() => import('@/shop/src/components/shared/search/ShopSearch'))

const MobileLeftmostItem: FC = () => {
  const { pathname } = useRouter()

  return (
    <>
      {!['/', '/404', '/500'].includes(pathname) && (
        <>
          <Breadcrumbs />
          <div className="w-[1px] ml-3 bg-gray-600 h-3/6"></div>
        </>
      )}
    </>
  )
}

const LeftmostItem: FC = () => (
  <>
    <LgBreakpoint max={<MobileLeftmostItem />} />
    <ShopSearch />
  </>
)

export default LeftmostItem
