import type { FC, ReactNode } from 'react'
import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import AddToCartConfirmationProvider from './confirmation/AddToCartConfirmationProvider'
import { loadStoredCart, storeCart } from './provider/storage'
import type { ShoppingCartState, ShoppingCartAction } from './provider/types'
import updateCartItems from './provider/util/updateCartItem'
import removeCartItem from './provider/util/removeCartItem'
import StoredShoppingCartLoader from './provider/StoredShoppingCartLoader'

const reducer = (state: ShoppingCartState, action: ShoppingCartAction) => {
  let newState: ShoppingCartState
  switch (action.type) {
    case 'add-to-cart': {
      const incoming = Array.isArray(action.items) ? action.items : [action.items]

      // The caller forgot to put the elements inside the
      // array, no update needed
      if (incoming.length === 0) {
        return state
      }

      // For updating single deals, the caller will have to provide
      // the new total quantity
      newState = updateCartItems(state, incoming, action.replace)
      break
    }

    case 'remove-from-cart':
      newState = removeCartItem(state, action.item, action.purge)
      break

    case 'empty-cart':
      newState = []
      break

    case 'initialize-cart':
      // Do not fall through, do not want to `storeCart`.
      return action.items

    default:
      throw new Error(`Unexpected shopping cart reducer action: ${JSON.stringify(action)}`)
  }

  storeCart(newState)
  return newState
}

const [
  /**
   * Use the shopping cart items.
   *
   * Do NOT mutate the array. Use `useShoppingCartOpsDispatch` to modify shopping
   * cart contents.
   *
   * Only render shopping cart contents inside `ClientOnly`, because they get
   * loaded from localStorage.
   */
  useShoppingCart,
  /**
   * Dispatcher for the shopping cart ops.
   *
   * @example
   * const cart = useShoppingCart()
   * const dispatch = useShoppingCartOpsDispatch()
   * dispatch({ type: "add-to-cart", item: { productId: "wow", totalQuantity: 1337 })
   * // => cart: [{ productId: "wow", totalQuantity: 1337 }]
   * dispatch({ type: "add-to-cart", item: { productId: "wow", totalQuantity: 420 })
   * // => cart: [{ productId: "wow", totalQuantity: 420 }]
   */
  useShoppingCartOpsDispatch,
  /*
   * Separated the dispatch context, so every "add to cart" button doesn't
   * have to update when they're clicked.
   *
   * Passing the dispatch as it is, otherwise have to make a bunch of
   * useCallbacks here. Note to self to add some helper funcs if it gets
   * too messy (like addToCart(dispatch, "item")).
   */
  ReducerProvider,
] = createReducerContext(
  reducer,
  // Even though the stored cart by itself can have bad items, we'll
  // still load it initially because it'll likely be ok and, since the
  // ui gets loaded before the getShopItems query finishes, it'll show
  // something.
  loadStoredCart(),
  'ShoppingCartProvider'
)

const ShoppingCartProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <ReducerProvider>
      <StoredShoppingCartLoader>
        {/* Sub context of the shopping cart */}
        <AddToCartConfirmationProvider>{children}</AddToCartConfirmationProvider>
      </StoredShoppingCartLoader>
    </ReducerProvider>
  )
}

export { useShoppingCart, useShoppingCartOpsDispatch }
export default ShoppingCartProvider
