import { createContext, createElement, ReactNode, useContext, useReducer } from 'react'

/**
 * TODO: move to a better place when cleaning up libs
 * modified createReducerContext, with separated dispatch
 *
 * @see https://github.com/streamich/react-use/blob/master/src/factory/createReducerContext.ts
 */
const createReducerContext = <R extends React.Reducer<any, any>>(
  reducer: R,
  defaultInitialState: React.ReducerState<R>,
  debugName: string
) => {
  const stateContext = createContext<React.ReducerState<R> | undefined>(undefined)
  const dispatchContext = createContext<React.Dispatch<React.ReducerAction<R>> | undefined>(
    undefined
  )
  const providerFactory = (stateProps: any, dispatchProps: any, children: ReactNode) =>
    createElement(
      stateContext.Provider,
      stateProps,
      createElement(dispatchContext.Provider, dispatchProps, children)
    )

  const ReducerProvider: React.FC<{
    initialState?: React.ReducerState<R>
    children?: ReactNode
  }> = ({ children, initialState }) => {
    const [state, dispatch] = useReducer<R>(
      reducer,
      initialState !== undefined ? initialState : defaultInitialState
    )
    return providerFactory({ value: state }, { value: dispatch }, children)
  }

  const useStateContext = () => {
    const state = useContext(stateContext)
    if (state == null) {
      throw new Error(
        `useReducerContext must be used inside a ReducerProvider: '${debugName}' is missing.`
      )
    }
    return state
  }

  const useDispatchContext = () => {
    const dispatch = useContext(dispatchContext)
    if (dispatch == null) {
      throw new Error(
        `useReducerContext must be used inside a ReducerProvider: '${debugName}' is missing.`
      )
    }
    return dispatch
  }

  return [useStateContext, useDispatchContext, ReducerProvider] as const
}

export default createReducerContext
