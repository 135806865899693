import type { FC, ReactNode } from 'react'
import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import type { ShopSearchAction, ShopSearchState } from './util/types'
import { loadStoredSearchResults } from './util/storage'
import { updateRecent } from './util/recent'

const initialState: ShopSearchState = {
  isOpen: false,
  searchQuery: '',
  filter: undefined,
  recent: loadStoredSearchResults(),
}

const reducer = (state: ShopSearchState, action: ShopSearchAction): ShopSearchState => {
  switch (action.type) {
    case 'select-result':
    case 'set-is-open': {
      return {
        ...state,
        recent:
          // Only store deals in recent results
          action.type === 'select-result' && action.resultType === 'product'
            ? updateRecent(action.id, state.recent)
            : state.recent,
        isOpen: action.isOpen,
        // Reset the search query if dialog closes
        searchQuery: action.isOpen ? state.searchQuery : '',
        // Reset the filter if dialog opens
        filter: action.isOpen ? undefined : state.filter,
      }
    }
    case 'update-search-query':
      return { ...state, searchQuery: action.searchQuery }
    case 'update-filter':
      // Close dialog and clear search query if filter is searched
      return {
        ...state,
        filter: { name: action.name, trait: action.trait },
        isOpen: false,
        searchQuery: '',
      }

    default:
      throw new Error(`Unexpected shop search reducer action: ${JSON.stringify(action)}`)
  }
}

const [useShopSearch, useShopSearchOpsDispatch, ReducerProvider] = createReducerContext(
  reducer,
  initialState,
  'ShopSearchProvider'
)

const ShopSearchProvider: FC<{ children?: ReactNode }> = ({ children }) => (
  <ReducerProvider>{children}</ReducerProvider>
)

export { useShopSearch, useShopSearchOpsDispatch }
export default ShopSearchProvider
