import type { PrpsDubiWallet } from '../wallet/PrpsDubiWallet'
import type ConnectableWallet from './ConnectableWallet'
import { SupportedNetworkIDs } from '../types'
import type WalletConnectProvider from '@walletconnect/web3-provider'
import type { ConnectableWalletNames } from '@g4g/utils/src/wallet/detectorStorage'

export class WalletConnectWallet implements ConnectableWallet {
  private _wc: WalletConnectProvider | undefined

  public get logo(): string {
    return '/shared-assets/img/wallet_connect_logo.png'
  }

  public get name(): ConnectableWalletNames {
    return 'WalletConnect'
  }

  public get isAvailable(): boolean {
    return true
  }

  public async enable(): Promise<PrpsDubiWallet> {
    const { default: WalletConnectProvider } = await import('@walletconnect/web3-provider')

    const wc = new WalletConnectProvider({
      rpc: {
        [SupportedNetworkIDs.Mainnet]: 'https://polygon-rpc.com/',
        [SupportedNetworkIDs.Mumbai]: 'https://rpc-mumbai.maticvigil.com/',
        [SupportedNetworkIDs.Local]: 'http://localhost:8545/',
      },
      qrcode: true,
    })
    await wc.enable()
    this._wc = wc
    const { default: Web3PrpsDubiWallet } = await import('../wallet/Web3PrpsDubiWallet')
    const { Web3Provider } = await import('@ethersproject/providers')
    return new Web3PrpsDubiWallet(this, new Web3Provider(wc))
  }

  public async disconnect(): Promise<void> {
    if (this._wc) {
      await this._wc.disconnect()
    }
  }
}
