import createReducerContext from '@g4g/utils/src/react/createReducerContext'
import { Price } from '../hooks/useCalculatedPrices'

export interface AddToCartConfirmationState {
  // Wether we updated or added to cart
  wasUpdate?: boolean
  addedShopItemId?: string
  // This is to persist query params (filters, etc.)
  // between the whole lifecycle of the confirmation:
  // navigate to where the trigger lives -> triggering confirmation -> display confirmation
  persistedQueryStr: string
  loading: boolean
  addedShopItemPrices?: Price
}

export type AddToCartConfirmationAction =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | { type: 'persist-query'; query: any }
  | { type: 'added-confirm'; addedShopItemId: string; wasUpdate?: boolean }
  | { type: 'reset' }
  | { type: 'push-to-cart'; prices?: Price }
  | { type: 'toggle-loading'; loading: boolean }

const initialState: AddToCartConfirmationState = {
  addedShopItemId: undefined,
  persistedQueryStr: '',
  loading: false,
  wasUpdate: false,
  addedShopItemPrices: undefined,
}

const reducer = (
  state: AddToCartConfirmationState,
  action: AddToCartConfirmationAction
): AddToCartConfirmationState => {
  switch (action.type) {
    case 'added-confirm':
      return { ...state, addedShopItemId: action.addedShopItemId, wasUpdate: action.wasUpdate }
    case 'persist-query': {
      const queryStr =
        process.env.NODE_ENV === 'development'
          ? `${new URLSearchParams(action.query).toString()}`
          : action.query
      return { ...state, persistedQueryStr: queryStr }
    }
    case 'toggle-loading':
      return { ...state, loading: action.loading }
    case 'push-to-cart':
      return {
        ...state,
        loading: true,
        addedShopItemPrices: action.prices ? { ...action.prices } : undefined,
      }
    case 'reset':
      return initialState
    default:
      throw new Error(`Unexpected AddToCart Confirmation action: ${JSON.stringify(action)}`)
  }
}

const [
  useAddToCartConfirmation,
  useAddToCartConfirmationOpsDispatch,
  AddToCartConfirmationProvider,
] = createReducerContext(reducer, initialState, 'AddToCartConfirmationProvider')

export { useAddToCartConfirmation, useAddToCartConfirmationOpsDispatch }
export default AddToCartConfirmationProvider
