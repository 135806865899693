export const DETECTED_WALLET_KEY = 'cos_detected_wallet'

export type ConnectableWalletNames = 'MetaMask' | 'WalletConnect' | 'E2E'

export const clearDetectedWalletName = () => {
  try {
    localStorage.removeItem(DETECTED_WALLET_KEY)
  } catch {}
}

export const storeDetectedWalletName = (name: ConnectableWalletNames) => {
  try {
    localStorage.setItem(DETECTED_WALLET_KEY, name)
  } catch (error) {
    clearDetectedWalletName()
    throw error
  }
}

const names: ConnectableWalletNames[] = ['MetaMask', 'WalletConnect', 'E2E']

export const loadDetectedWalletName = (): ConnectableWalletNames | undefined => {
  try {
    const name = localStorage.getItem(DETECTED_WALLET_KEY) as ConnectableWalletNames
    if (!name) {
      return undefined
    }
    if (!names.includes(name)) {
      throw new Error('Invalid detected wallet name ' + name)
    }
    return name
  } catch (error) {
    clearDetectedWalletName()
    throw error
  }
}
