import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ShoppingCartIcon } from '@heroicons/react/20/solid'
import { useShoppingCart } from '../../shopping-cart/ShoppingCartProvider'
import ClientOnly from '@g4g/ui/src/utility/ClientOnly'
import { classNames } from '@g4g/utils/src/react/class-names'
import { cartItemsCount } from '../../shopping-cart/util/cart'

const ShoppingCartButton: React.FC = () => {
  const router = useRouter()
  const items = useShoppingCart()

  return (
    <ClientOnly>
      <Link
        href="/cart"
        className={classNames(
          'flex py-4 px-2 sm:px-4 sm:h-full items-center',
          'lg:px-5 lg:space-x-4 rounded-md lg:bg-white/10',
          'lg:hover:bg-white/20 lg:ring-2',
          router.route === '/cart' ? 'lg:ring-shopStdGreen' : 'lg:ring-transparent',
          items.length > 0 ? 'text-shopStdGreen' : 'text-secondaryLight'
        )}
      >
        <span
          data-cy="shop-cart-top-bar-btn-quantity"
          className="text-sm font-bold lg:text-xl font-poppins"
        >
          {cartItemsCount(items)} <span className="sr-only">items in your shopping cart</span>
        </span>
        <div className="w-5 h-5 lg:w-6 lg:h-6">
          <ShoppingCartIcon aria-hidden />
        </div>
      </Link>
    </ClientOnly>
  )
}

export default ShoppingCartButton
