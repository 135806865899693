import { gtm_dataLayer_push } from '.'

/**
 * Tracked GA4 recommended events
 * @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
 */
export type GTM_ECommerce_TrackedEvents =
  | 'add_to_cart'
  // Connect wallet
  | 'add_payment_info'
  // Login or Select recipient
  | 'add_shipping_info'
  // Visit /checkout
  | 'begin_checkout'
  | 'purchase'
  // Transaction fail
  | 'refund'
  | 'remove_from_cart'
  // Visit /cart
  | 'view_cart'
  // Visit deal detail
  | 'view_item'

const gtm_ecommerce_event = <T>(
  eventType: GTM_ECommerce_TrackedEvents,
  items: T[],
  params?: Partial<{
    event: object
    item: (a: T, i: number, arr: T[]) => object
  }>
) => {
  /**
   * It's recommended that you use the following command to clear the
   * e-commerce object prior to pushing an e-commerce event to the data layer.
   * Clearing the object will prevent multiple e-commerce events on a page
   * from affecting each other.
   *
   * @see https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#clear_the_ecommerce_object
   */
  window?.dataLayer?.push({ ecommerce: null })
  gtm_dataLayer_push(eventType, {
    ecommerce: {
      currency: 'USD',
      items: items.map((item, index, arr) => ({
        /**
         * Common item params
         */
        index, // The index/position of the item in a list.
        currency: 'USD', // Tracking the original price in USD by default
        /**
         * Additional item params
         */
        ...params?.item?.(item, index, arr),
      })),
      /**
       * Additional event params
       */
      ...params?.event,
    },
  })
}

export default gtm_ecommerce_event
