import type { Dubi } from './Dubi'
import type { Heroes } from './Heroes'
import type { Pets } from './Pets'
import type { Purpose } from './Purpose'
interface EthereumProvider {
  isMetaMask?: boolean
  request: any
}

declare global {
  interface Window {
    ethereum?: EthereumProvider
  }
}

/**
 * Callable contract names
 */
export declare type CallableContractNames = 'Purpose' | 'Dubi' | 'Heroes' | 'Pets'

/**
 * Non human externally owned accounts.
 */
export declare type NonHumanEOANames = 'SeasonZeroBurnAddress'

/**
 * Deployed g4g ERC721 token names.
 */
export declare type NFTNames = 'Heroes' | 'Pets'
/**
 * Deployed g4g ERC20 token names.
 */
export declare type ERC20Names = 'Purpose' | 'Dubi'
/**
 * External ERC20 token names.
 */
export declare type ExternalERC20Names = 'USDC'
/**
 * Deployed g4g ERC20 token symbols.
 */
export declare type ERC20Symbols = 'PRPS' | 'DUBI'
/**
 * External ERC20 token symbols.
 */
export declare type ExternalERC20Symbols = 'USDC'
/**
 * Currency name used on a certain network
 */
export declare type NativeCurrencyNames = 'Polygon'
/**
 * Currency symbol used on a certain network
 */
export declare type NativeCurrencySymbols = 'MATIC'

/**
 * Networks used in the past.
 */
export enum LegacyNetworkIDs {
  Mainnet = 1,
  Ropsten = 3,
  Bsc = 56,
  BscTest = 97,
}

/**
 * Currently supported networks.
 */
export enum SupportedNetworkIDs {
  Mainnet = 137,
  Mumbai = 80001,
  Local = 1337,
}

export declare interface NativeCurrencyDetail {
  name: NativeCurrencyNames
  symbol: NativeCurrencySymbols
  decimals: 18
}

export declare interface SupportedNetworkDetail {
  chainId: SupportedNetworkIDs
  chainName: string
  tokens?: ERC20Detail[]
  // Some of these are prefilled automatically
  // when adding networks
  nativeCurrency?: NativeCurrencyDetail
  rpcUrls?: string[]
  blockExplorerUrls?: string[]
}

export declare interface ERC20Detail {
  name: ERC20Names | ExternalERC20Names
  symbol: ERC20Symbols | ExternalERC20Symbols
  address: string
  decimals: number
  image?: string
}

export declare interface ERC20Preview {
  name: ERC20Names | ExternalERC20Names
  symbol: ERC20Symbols | ExternalERC20Symbols
}

export declare type callableContract = {
  Dubi: Dubi
  Purpose: Purpose
  Heroes: Heroes
  Pets: Pets
}

/**
 * Callable Deployed Contracts.
 */
export declare type CallableContracts = Purpose | Dubi | Heroes | Pets

/**
 * Callable Deployed ERC20 Tokens.
 */
export declare type CallableERC20Contracts = Purpose | Dubi

/**
 * Callable Deployed ERC721 Tokens.
 */
export declare type CallableNFTContracts = Heroes | Pets

/**
 * TODO: maybe a different way
 * but need to easily check hook errors
 * from within tests
 */
export enum WalletModalErrorCodes {
  // Thrown from 3rd party
  General = 1000,
  AccountNotFound = 1001,
  ChainIdNotFound = 1002,
  ProviderNotFound = 1003,
  InvalidInput = 1004,
  LoginDetectEmpty = 1005,
  // Rpc errors that we handle
  UserRejectedRequest = 4001,
  ChainNotAddedYet = 4902,
  ResourceUnavailable = -32002,
}
