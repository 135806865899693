import ConnectableWallet from './ConnectableWallet'
import detectEthereumProvider from '@metamask/detect-provider'
import type Web3PrpsDubiWallet from '../wallet/Web3PrpsDubiWallet'
import type { ConnectableWalletNames } from '@g4g/utils/src/wallet/detectorStorage'

export class MetaMaskWallet implements ConnectableWallet {
  public get logo(): string {
    return '/shared-assets/img/metamask_logo.png'
  }

  public get name(): ConnectableWalletNames {
    return 'MetaMask'
  }

  public get isAvailable() {
    return !!window.ethereum
  }

  private _providerPromise: Promise<any>

  constructor() {
    this._providerPromise = detectEthereumProvider({
      silent: true,
    }).catch(() => {})
  }

  public async enable(): Promise<Web3PrpsDubiWallet> {
    if (!this.isAvailable) {
      throw new Error('MetaMask not detected')
    }
    const provider = await this._providerPromise
    if (!provider) {
      throw new Error('No provider')
    }

    await provider.request({
      method: 'eth_requestAccounts',
    })
    const { Web3Provider } = await import('@ethersproject/providers')
    const { default: Web3PrpsDubiWallet } = await import('../wallet/Web3PrpsDubiWallet')

    return new Web3PrpsDubiWallet(this, new Web3Provider(provider))
  }

  public async disconnect() {}
}
