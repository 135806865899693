import { useMedia } from 'react-use'

export type MediaBreakpoints = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

const breakpoints: { [k in MediaBreakpoints]: number } = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
} as const

export const useMediaMinSm = () => useMedia(`(min-width: ${breakpoints.sm}px)`, false)
export const useMediaMaxSm = () => useMedia(`(max-width: ${breakpoints.sm - 1}px)`, false)
export const useMediaMinMaxSm = () => [useMediaMinSm(), useMediaMaxSm()]

export const useMediaMinMd = () => useMedia(`(min-width: ${breakpoints.md}px)`, false)
export const useMediaMaxMd = () => useMedia(`(max-width: ${breakpoints.md - 1}px)`, false)
export const useMediaMinMaxMd = () => [useMediaMinMd(), useMediaMaxMd()]

export const useMediaMinLg = () => useMedia(`(min-width: ${breakpoints.lg}px)`, false)
export const useMediaMaxLg = () => useMedia(`(max-width: ${breakpoints.lg - 1}px)`, false)
export const useMediaMinMaxLg = () => [useMediaMinLg(), useMediaMaxLg()]

export const useMediaMinXl = () => useMedia(`(min-width: ${breakpoints.xl}px)`, false)
export const useMediaMaxXl = () => useMedia(`(max-width: ${breakpoints.xl - 1}px)`, false)
export const useMediaMinMaxXl = () => [useMediaMinXl(), useMediaMaxXl()]

export const useMediaMin2Xl = () => useMedia(`(min-width: ${breakpoints.xxl}px)`, false)
export const useMediaMax2Xl = () => useMedia(`(max-width: ${breakpoints.xxl - 1}px)`, false)
export const useMediaMinMax2Xl = () => [useMediaMin2Xl(), useMediaMax2Xl()]
