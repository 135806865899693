import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

/**
 * A custom link to throw graphql error to the real error chain
 * so that the retryLink processes them.
 *
 * Note: retryLink does not (currently) handle retries for GraphQL
 * errors in the response, only for network errors.
 *
 * @see https://github.com/apollographql/apollo-link/issues/541
 */
export const gqlErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    if (data && data.errors && data.errors.length > 0) {
      throw new Error(JSON.stringify(data))
    }
    return data
  })
})

// Log any GraphQL errors or network error that occurred
export const errorLink = onError(({ graphQLErrors, networkError, response }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError && Object.keys(networkError).length !== 0) {
    console.log(`[Network error]: ${JSON.stringify(networkError)}`)
  }
  if (response && Object.keys(response).length !== 0) {
    console.log(`[Error response] ${JSON.stringify(response)}`)
  }
})
