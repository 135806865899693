export declare type SocialAccountMajorProvider = 'Twitch' | 'Google' | 'Facebook' | 'Apple'
// Google has many names, helps distinguish between
// Google Youtube & Google GooglePlay
export declare type SocialAccountProviderAlias = 'GooglePlay' | 'Youtube'

export declare interface SocialAccountState {
  socialAccount?: SocialAccountStoredData
  error?: Error
  failCode?: OauthFailCodes
  loading: boolean
}

export declare type SocialAccountAction =
  | { type: 'sign-out' }
  | { type: 'load-account' }
  | { type: 'sign-in'; data: SocialAccountStoredData }
  | { type: 'oauth-error'; failCode?: OauthFailCodes; error?: Error }

export declare interface AuthCodeUrlConstructParams {
  provider: SocialAccountMajorProvider
  clientId: string
  cosBaseUrl: string
  state: string
}

export declare interface AuthCodeState {
  userData?: FetchCosAuthFromPlatformOkResponseData
  loading: boolean
  error?: Error
  failCode?: OauthFailCodes
  returnUrl?: string
}

export declare type AuthCodeAction =
  | { type: 'failure'; failCode?: OauthFailCodes; error: Error }
  | { type: 'success'; userData: FetchCosAuthFromPlatformOkResponseData; returnUrl?: string }

export enum AuthCodeFailCodes {
  // User did not approve the Mobile Minigames app
  // in the oauth flow
  AccessDenied = 'AccessDenied',
  // The returned oauth state does not match the one
  // that was generated by landing on the login page
  StateMismatch = 'StateMismatch',
  // Failed to load the stored state
  StateLoadFailed = 'StateLoadFailed',
  // No state was echoed back from the oauth provider
  NoEchoedState = 'NoEchoedState',
  // Failed to decode oauth state
  StateDecodeFailed = 'StateDecodeFailed',
  // Unexpected/Unrecognized error
  Unexpected = 'Unexpected',
}

export declare type FetchCosAuthFromPlatformReqPlatform =
  | 'Twitch'
  | 'Youtube'
  | 'Facebook'
  | 'GooglePlay'
  | 'Apple'

export declare type FetchCosAuthFromPlatformRequestData = {
  // Or access_token for facebook implicit flow,
  // but sent as "authCode"
  authCode: string
  platform: FetchCosAuthFromPlatformReqPlatform
  clientType: 'Shop'
  appleDisplayName?: string
}

export enum UnityHttpRequests {
  FetchCosAuthFromPlatformRequest = 'FetchCosAuthFromPlatformRequest',
}

export declare type FetchCosAuthFromPlatformOkResponseData = {
  userId: string
  displayName: string
}

export declare type FetchCosAuthFromPlatformOkResponseDataAll =
  FetchCosAuthFromPlatformOkResponseData & {
    jwt: string
    expiresIn: number
    scope: string[]
    avatarKey?: string
    bestGameLevel?: number
  }

export enum FetchCosAuthFromPlatformFailCodes {
  // trying to connect to google account that doesn’t have youtube setup
  MissingYoutubeChannel = 'MissingYoutubeChannel',
  // trying to connect to a platform that is not supported
  NotAuthenticated = 'NotAuthenticated',
  // moderators can only login with Facebook
  ModOrStaffWithoutFb = 'ModOrStaffWithoutFb',
  // user did not connect this platform account to the game
  ConvertCosIdFailed = 'ConvertCosIdFailed',
  // user opts out from the game
  AccountLocked = 'AccountLocked',
  // non-specifc general error
  Default = 'Default',
  None = 'None',
}

export declare type OauthFailCodes = AuthCodeFailCodes | FetchCosAuthFromPlatformFailCodes

export declare type FetchCosAuthFromPlatformFailResponseData = [
  string,
  FetchCosAuthFromPlatformFailCodes
]

export declare type FetchCosAuthFromPlatformOk = {
  FetchCosAuthFromPlatformOkResponse: FetchCosAuthFromPlatformOkResponseDataAll
}

export declare type FetchCosAuthFromPlatformFail = {
  FetchCosAuthFromPlatformFailResponse: FetchCosAuthFromPlatformFailResponseData
}

export declare type FetchCosAuthFromPlatformResponseFailOrOk =
  | FetchCosAuthFromPlatformOk
  | FetchCosAuthFromPlatformFail

export declare type FetchCosAuthFromPlatformResponseFailCodeOrOkData =
  | FetchCosAuthFromPlatformOkResponseData
  | FetchCosAuthFromPlatformFailCodes

export type UnityHttpResponses = {
  [UnityHttpRequests.FetchCosAuthFromPlatformRequest]: FetchCosAuthFromPlatformResponseFailOrOk
}

export type SocialAccountStoredData = {
  userData: FetchCosAuthFromPlatformOkResponseData
  platform: FetchCosAuthFromPlatformReqPlatform
}

// Google Tag Manager
declare global {
  interface Window {
    dataLayer: Record<string, any>[]
  }
}
