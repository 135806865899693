import { useMemo } from 'react'
import { useActiveRecipientShopItems } from '../../../shared/hooks/useActiveRecipientShopItems'
import { filterCartProducts } from '../../util/shopItem'
import { loadStoredCart } from '../storage'
import { ShoppingCartState } from '../types'

/** Filters out stored cart products that aren't returned by backend (because e.g. user is not eligible for a deal). */
export const useStoredCart = (): ShoppingCartState => {
  const products = useActiveRecipientShopItems().data?.getShopItems

  const cartProducts = useMemo(
    // Do not override cart if products have not yet loaded. Doing so
    // causes tests to sometimes proceed with empty cart, and I don't see
    // the benefit of doing that.
    () => (products ? filterCartProducts(loadStoredCart(), products) : loadStoredCart()),
    [products]
  )
  return cartProducts
}
