import { useShoppingCart } from '../ShoppingCartProvider'
import type { GetShopItems_getShopItems } from '@g4g/graphql/src/shop/__generated__/GetShopItems'
import type { CartProduct, ShoppingCartState } from '../provider/types'
import { toShopItemUniqueId } from '../util/cart'
import { useActiveRecipientShopItems } from '../../shared/hooks/useActiveRecipientShopItems'
import { fillProductPrice } from '@/shop/src/utils/shopItemUtils'
import { Price, useCalculatedPrices } from './useCalculatedPrices'

export const productsInCart = (
  items: ShoppingCartState,
  data: GetShopItems_getShopItems[] | null | undefined,
  individualPrices: Price[]
) =>
  items
    .map((item, i) => {
      const cartProduct = {
        product: data?.find((p) => item._id === toShopItemUniqueId(p)),
        totalQuantity: item.totalQuantity,
      } as CartProduct

      return cartProduct.product && individualPrices && individualPrices[i]
        ? fillProductPrice(cartProduct, individualPrices[i])
        : cartProduct
    })
    // Need to cast this because `.filter(Boolean)` doesn't type correctly.
    .filter((item) => item.product) as {
    product: GetShopItems_getShopItems
    totalQuantity: number
  }[]

const ethToWeiString = (eth: string) => {
  if (!eth) {
    return ''
  }
  const [fulls, decimals = ''] = eth.trim().split('.')
  let part1 = fulls
  if (fulls === '0') {
    part1 = ''
  }
  return part1 + decimals.padEnd(18, '0')
}

/**
 * Returns the product data for each item in the cart,
 * specific ones if `ids` and `filterBy` prop name is provider.
 *
 * Filters by `productId` by default.
 */
const useCartProducts = () => {
  const products = useActiveRecipientShopItems()
  const items = useShoppingCart()

  const data = (products.data ?? products.previousData)?.getShopItems

  const prices = useCalculatedPrices(items)

  return {
    products: productsInCart(items, data, prices.individual),
    priceWei: ethToWeiString(prices.price),
    priceWeiDubi: ethToWeiString(prices.dubiPrice),
    price: prices.price ?? '',
    priceDubi: prices.dubiPrice ?? '',
    originalPrice: prices.originalPrice ?? '',
  }
}

export default useCartProducts
