import dynamic from 'next/dynamic'
import { FC } from 'react'
import { getProduct } from '../shopping-cart/util/cart'
import { useActiveRecipientShopItems } from './hooks/useActiveRecipientShopItems'
import { useRouter } from 'next/router'
import type { StaticBreadcrumb } from '@g4g/ui/src/components/Breadcrumbs'
import ClientOnly from '@g4g/ui/src/utility/ClientOnly'
const UIBreadcrumbs = dynamic(() => import('@g4g/ui/src/components/Breadcrumbs'))

const prefixes: Record<string, string> = {
  '/history/id/[cosId]': 'History: ',
  '/history/hash/[hash]': 'Transaction: ',
}

const useProductLabeler = () => {
  const shopItems = useActiveRecipientShopItems().data?.getShopItems
  return (name: string, pathname: string) => {
    const product = getProduct(name, shopItems)
    return product?.name || (prefixes[pathname] ?? '') + name
  }
}

const pathways: Record<string, StaticBreadcrumb[]> = {
  '/checkout': [
    {
      label: 'Cart',
      path: '/cart',
    },
  ],
  '/checkout/confirmation': [
    {
      label: 'Cart',
      path: '/cart',
    },
  ],
  '/recipient': [
    {
      label: 'Cart',
      path: '/cart',
    },
  ],
}

const useStaticPaths = () => {
  const { pathname } = useRouter()
  const base: StaticBreadcrumb[] = [
    {
      label: 'Deals',
      path: '/deals',
    },
  ]
  return base.concat(pathways[pathname] || [])
}

const Breadcrumbs: FC = () => {
  const labeler = useProductLabeler()
  const staticPaths = useStaticPaths()

  return (
    <ClientOnly>
      <UIBreadcrumbs
        crumbClassName="text-white/80 transition duration-100 ease-out hover:text-white hover:ease-in"
        omitPaths={['id', 'hash', 'wallet', 'history']}
        staticBasePaths={staticPaths}
        transformLabel={labeler}
      />
    </ClientOnly>
  )
}

export default Breadcrumbs
