import type { RecipientState } from '../types'

export default function removeFromRecent(state: RecipientState, cosId: string): RecipientState {
  const recent = state.recentRecipients.filter((recentCosId) => recentCosId !== cosId)

  // The recipient that is being removed is
  // also the selected one, deselect is needed
  if (state.currentRecipient === cosId) {
    return { ...state, recentRecipients: recent, currentRecipient: undefined }
  }

  return { ...state, recentRecipients: recent }
}
