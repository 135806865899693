import Cookies from 'js-cookie'
import { gtm_dataLayer_push } from '.'
import { COS_SOCIAL_ACCOUNT_COOKIE_KEY } from '../react/cookie'
import { loadDetectedWalletName } from '../wallet/detectorStorage'

const getRecipientId = () => {
  try {
    const _history = localStorage.getItem('cos_recipient_history')
    if (!_history) {
      return
    }
    const recipientId = JSON.parse(_history)?.recent[0] as string | undefined
    return recipientId
  } catch (error) {
    throw new Error('gtm common params, failed to parse recipient history')
  }
}

const getUserId = () => {
  try {
    const _user = Cookies.get(COS_SOCIAL_ACCOUNT_COOKIE_KEY)
    if (!_user) {
      return
    }
    const userId = JSON.parse(_user)?.userData?.userId as string | undefined
    return userId
  } catch {
    throw new Error('gtm common params, failed to parse userData')
  }
}

/**
 * Push all the user scoped params with this event.
 * These should all be loaded from storage/cookies,
 *
 */
const gtm_user_data = () => {
  gtm_dataLayer_push('user_data', {
    user_id: getUserId(), // Logged in userId
    recipient_id: getRecipientId(), // Recipient userId
    wallet_name: loadDetectedWalletName(), // Connected Wallet name
  })
}

export default gtm_user_data
